import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from './components';
import SimpleSidebar from './components/sidebar/sidebar';
import { Box, Flex,useMediaQuery,Hide, Show } from '@chakra-ui/react';

const Main = (props) => {
    const { children } = props;
    const [isMobile] = useMediaQuery('(min-width: 375px)')

    return (
        <Box bg='#2B2E31' position='relative'>
            <Header />
            <main bg='#2B2E31'>
               <Flex pt={32} >
                    <SimpleSidebar/>
                    <Box w={['100%','86%','86%','86%' ]} pt='2' bg='#2B2E31' color='gray.100'>
                        {children}
                    </Box>
               </Flex>
            </main>
           <Show below='md'>
             <Footer />
           </Show>
        </Box>
    );
}

Main.propTypes = {
    children: PropTypes.node,
}

export default Main;
