import { Text, VStack,useToast,Box,Spinner,HStack, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BetCard from '../../components/dashboard/bet_card';
import TransactionCard from '../../components/dashboard/transaction_card';
import ApiService from '../../services/api_service';
import * as ROUTES from '../../utils/constants/routes';
const Transactions = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const {
        data, isLoading, isError, error,
    } = useQuery('transactions', () => {
        if (!userCredentails.id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
      return  ApiService.transactions()
    }, {
        onSuccess: () => {
            // toast({
            //     title: "Success",
            //     description: 'User Transactions Fetched  Successfully',
            //     status: "success",
            //     duration: 9000,
            //     isClosable: true,
            // });
        }
    });
    return (
        <Box p={6} minH='100vh' w='100%'>
         {isLoading && <Spinner />}
        {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
        {data && (
            <>
            <Text mt="4">Latest Transactions</Text>
            <Flex alignItems='flex-start' py="4" w="100%" flexWrap='wrap'>
            {[...data.payments].reverse().map((transaction) => (
                    <TransactionCard transaction={transaction} key={transaction.id} />
                ))}
            </Flex></>
        )}
        </Box>
    );
};

export default Transactions;