import { Avatar,Badge,HStack, Text, VStack,Card } from '@chakra-ui/react';
import React from 'react';

const RefferalCard = ({referral,level}) => {
    const {firstname,lastname,username} = referral;
    return (
            <Card borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' mt={4} w='100%'>
                <HStack>
                <Avatar src={''} name={`${firstname} ${lastname}`}  />
                <VStack alignItems='flex-start'>
                    <Text fontSize='sm'>{`${firstname} ${lastname}`} <Badge ml='1' colorScheme='green'>{`Level ${level}`}</Badge></Text>
                    <Text fontSize='sm'>{username} </Text>
                </VStack>
            </HStack>
            </Card>
    );
};

export default RefferalCard;