import React from 'react';
import { Avatar, Badge, HStack, Text, VStack, Card, Spacer } from '@chakra-ui/react';
import moment from 'moment';

const UserCard = ({ user }) => {
    const { otp, email, username, wishlist, orders,cart, createdAt } = user;
    return (
        <Card borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' m={4} w='20%'>
            <HStack>
                <Avatar src={''} name={username} />
                <VStack alignItems='flex-start' overflow='hidden'>
                    <Text fontSize='sm'>{username} <Badge ml='1' colorScheme={otp? 'red':'green'}>{otp?`Unverified`:'verified'}</Badge></Text>
                    <Text fontSize='sm' noOfLines={1}>{email} </Text>
                </VStack>
            </HStack>
            <HStack w='100%' mt={2}>
                <Text fontSize={14}>Orders</Text>
                <Spacer />
                <Text>{orders.length}</Text>
            </HStack>
            <HStack w='100%'>
                <Text fontSize={14}>Items in Cart</Text>
                <Spacer />
                <Text>{cart.length}</Text>
            </HStack>
            <HStack w='100%'>
                <Text fontSize={14}>Items in WishList</Text>
                <Spacer />
                <Text>{wishlist.length}</Text>
            </HStack>
            <HStack w='100%'>
                <Text fontSize={14}>SignedUp</Text>
                <Spacer />
                <Text>{moment(createdAt).format('MMMM Do YYYY')}</Text>
            </HStack>
        </Card>
    );
};

export default UserCard;