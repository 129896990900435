import { Text, VStack, useToast, Box, Spinner, HStack, Flex, Spacer, Button, Input } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import WithdrawalCard from '../../components/dashboard/withdrawal_card';
import ApiService from '../../services/api_service';
import * as ROUTES from '../../utils/constants/routes';
import UserCard from '../../components/dashboard/user_card';
const UserList = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const {
        data, isLoading, isError, error,
    } = useQuery('usersList', () => {
        if (!userCredentails.id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
        return ApiService.users()
    }, {
        onSuccess: () => {
            // toast({
            //     title: "Success",
            //     description: 'Users Fetched  Successfully',
            //     status: "success",
            //     duration: 9000,
            //     isClosable: true,
            // });
        }
    });
    return (
        <Box p={6} minH='100vh' w='90vw'>
            {isLoading && <Spinner />}
            {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
            {data && (
                <>
                   <Flex justifyContent="center" marginBottom="4" gap='4'>
                   <Input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search users"
                        width='30%'
                    />
                    <Button onClick={()=>{}} colorScheme='blue' variant='solid'>Search</Button>
                   </Flex>
                   
                    <Flex alignItems='flex-start' py="4" w="100%" flexWrap='wrap'>
                        {[...data.users].reverse().filter(user => user.email.toLowerCase().includes(searchTerm.toLowerCase())).map((user) => (
                            <UserCard user={user} key={user._id} />
                        ))}
                    </Flex>
                </>
            )}
        </Box>
    );
};

export default UserList;