import React from 'react';
import PropTypes from 'prop-types';

const RouteWithLayout = (props) => {
  const { layout: Layout, element: Component} = props;
  
  return (
    <Layout>
        <Component />
    </Layout>
  );
}

RouteWithLayout.propTypes = {
    element: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string
}
  
export default RouteWithLayout;
