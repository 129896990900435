import { Text, VStack,useToast,Box,Spinner,HStack, Flex,Stat, StatLabel, StatNumber, StatHelpText, StatArrow, StatGroup } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BetCard from '../../components/dashboard/bet_card';
import ApiService from '../../services/api_service';
import * as ROUTES from '../../utils/constants/routes';

const OrderStatus = {
    Pending: "pending",
    Processing: "processing",
    Shipped: "shipped",
    Delivered: "delivered",
    Cancelled: "cancelled",
    Returned: "returned",
    Refunded: "refunded",
  };

const OrderHistory = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const {
        data, isLoading, isError, error,
    } = useQuery('orderHistory', () => {
        if (!userCredentails.id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
      return  ApiService.orderHistory()
    }, {
        onSuccess: () => {
            // toast({
            //     title: "Success",
            //     description: 'Orders Fetched  Successfully',
            //     status: "success",
            //     duration: 9000,
            //     isClosable: true,
            // });
        }
    });
    const countOrdersByStatus = (orders, status) => {
        return orders.filter(order => order.status === status).length;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return 'yellow.200';
            case 'processing':
                return 'blue.200';
            case 'shipped':
                return 'purple.200';
            case 'delivered':
                return 'green.200';
            case 'cancelled':
                return 'red.200';
            case 'returned':
                return 'orange.200';
            case 'refunded':
                return 'teal.200';
            default:
                return 'gray.200';
        }
    };
    return (
        <Box p={6} minH='100vh'>
         {isLoading && <Spinner />}
        {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
        {data && (
            <>
            <StatGroup>
                    {Object.values(OrderStatus).map(status => (
                        <Stat key={status} backgroundColor='#2D3748' borderRadius="8" p={8} mr={2} my={2}>
                            <StatLabel textTransform="capitalize">{status}</StatLabel>
                            <StatNumber>{countOrdersByStatus(data.orders, status)}</StatNumber>
                        </Stat>
                    ))}
                </StatGroup>
            <Text mt="4">Latest Orders</Text>
            <Flex alignItems='flex-start' py="4" w="100%" flexWrap='wrap'>
                {data.orders.map((order) => (
                    <BetCard order={order} key={order._id} />
                ))}
            </Flex></>
        )}
        </Box>
    );
};

export default OrderHistory;