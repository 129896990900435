import { Flex, Box,Text,VStack,HStack, Heading, Spacer,Image,FormControl,FormLabel,Input,FormHelperText, Button, useToast  } from '@chakra-ui/react';
import React, {useState} from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom';
import BetSlip from '../../components/home/bet_slip';
import { useQuery, useMutation } from 'react-query';
import ApiService from '../../services/api_service';
import { BiArrowBack } from 'react-icons/bi';
import * as ROUTES from '../../utils/constants/routes';

const WithdrawForm = () => {
    const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []);
    const toast = useToast();
    const navigate = useNavigate();
    const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }
      let { id } = useParams();
      const {
          data, isLoading, isError, error,
        } = useQuery(`withdraw${id}`, () => ApiService.getWithdrawalMethod(id));

        const [details,setDetails] = useState({});

        const { mutate, isLoading: isDepositLoading,} = useMutation(
            (details) => ApiService.withdrawStore(details),{
                onSuccess: (data) => {

                    if(data.code === 200){
                        toast({
                            title: "Success",
                            description:  'Withdrawal Request Submitted Successfully',
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                          });
                          navigate(ROUTES.LANDING);
                    }else{
                        toast({
                            title: "Error Occured",
                            description:  data.message.error.join("\n"),
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                            });
                    }
                    
                },
                onError: (error) => {
                    toast({
                        title: "Error Occured",
                        description:  error.errors ?error.errors.join(','): error.response ? error.response.data.message :'Something went wrong',
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                },
                
            })
            const onChange = (e) => {
                if (e.target.value) {
                  setDetails({ ...details, [e.target.name]: e.target.value })
                }
              }
    return (
        <Flex>
                <Box w='75%' pb='28'>
                   {
                          isLoading? <HStack justifyContent='center'><Text>Loading...</Text></HStack> : null
                   }
                   {
                    isError? <HStack justifyContent='center'><Text>{error}</Text></HStack> : null
                   }
                   {
                    data ? (
                        <Box >
                            <HStack bg={'#000'} p={2} borderRadius={4} my={8}>
                                <Link to={ROUTES.DEPOSIT_AND_WITHDRAW}>
                                <HStack>
                                <BiArrowBack />
                                    <Text my={2}>Back</Text>
                                </HStack> 
                                </Link>                           
                            </HStack>
                                <HStack px={28} my={18} alignItems='center'>
                                    <Heading size='lg' >{data[0].name}</Heading>
                                    <Spacer/>
                                    <Image src={`https://staging.volleyballeliteschampions.com/assets/images/withdraw/method/${data[0].image}`} width={20}/>
                                </HStack>                               
                                <Box p={8} px={20}>
                                    <Text>Minimum Withdrawal Amount: {parseFloat(data[0].min_limit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data[0].currency}</Text>
                                    <Text>Maximum Withdrawal Amount: {parseFloat(data[0].max_limit).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data[0].currency}</Text>
                                    <Heading size='sm' pt={4} pb={2}>Instructions</Heading>
                                    <Text p={4} pt={0} dangerouslySetInnerHTML={{__html: data[0].description}}/>
                                </Box>
                                <Heading px={20} my={4} size='md'>Withdrawal Form</Heading>
                                <Box px={24} pl={20}>
                                <FormControl isRequired>
                                    <FormLabel>Amount</FormLabel>
                                    <Input type='number'  name='amount' onChange={onChange}/>
                                    <FormHelperText>Amount you want to withdraw.</FormHelperText>
                                </FormControl>

                                <FormControl isRequired>
                                    <FormLabel>Wallet Address</FormLabel>
                                    <Input type='text' name='address' onChange={onChange} />
                                    <FormHelperText>TRC20 Address to recieve the withdrawal amount.</FormHelperText>
                                </FormControl>
                                <Button type='submit' bg='#F56403' my={4} width='100%' _hover={
                                    {
                                        bg:'#5F3822'
                                    }
                                }
                                onClick={
                                    () => {
                                        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
                                        if( !user ){
                                            toast({
                                            title: "Authentication Required",
                                            description: 'Login in to continue with this action ',
                                            status: "warning",
                                            duration: 9000,
                                            isClosable: true,
                                            });
                                        navigate(ROUTES.LOGIN);
                                    }
                                        if(details.amount === '' || details.address === ''){
                                            toast({
                                                title: "Error Occured",
                                                description:  'Please fill all the fields',
                                                status: "error",
                                                duration: 4000,
                                                isClosable: true,
                                              });
                                        }else{
                                            mutate({
                                                method_code: data[0].id,
                                                amount: details.amount,
                                                withdraw_information:{trc_20_wallet_address:{"field_name":details.address,"type":"text"}},
                                                user_id: user.id
                                            });
                                        }
                                    }
                                }
                                isLoading={isDepositLoading}
                                >Withdraw</Button>
                                </Box>

                        </Box>
                        ): null
                   }
                </Box>
                <Box w={'23%'} pl={4}>
                    <Text textAlign='center'>Bet Slips</Text>
                    <VStack spacing={3} mt='2'>
                        {
                            slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                        }
                        {
                            slips.map((slip,index) => {
                                return (
                                    <BetSlip
                                        match={slip.match}
                                        question={slip.question}
                                        option={slip.option}
                                        onRemove={()=>{ removeSlip(index)}}
                                    />
                                )
                            })
                        }
                    </VStack>
                </Box>
       </Flex>
    );
};

export default WithdrawForm;