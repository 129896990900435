import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Input,
    HStack,
    Image,
    VStack,
    useToast,
  
   Menu, MenuButton, MenuItem, MenuList,Avatar,Spacer

  } from '@chakra-ui/react';
  import {
    HamburgerIcon,
    CloseIcon,    
  } from '@chakra-ui/icons';
  import React,{useEffect, useState,useContext} from 'react';
  import * as ROUTES from '../../../../utils/constants/routes';
import { useMutation } from 'react-query';
import ApiService from '../../../../services/api_service';
import {FaUserCircle} from 'react-icons/fa'
import { SidebarContent } from '../sidebar/sidebar';
import { BiBell, BiChevronDown } from 'react-icons/bi';
import { VscSignOut,VscQuestion } from 'react-icons/vsc'

  export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ?{...JSON.parse(localStorage.getItem('user'))}: {});
    const { mutate,isLoading,} = useMutation(() => ApiService.logout(),{
      onSuccess: (data) => {
        toast({
          title: 'Signed out Successfully',
          description: 'You have successfully signed in',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        localStorage.removeItem('user');
        setUserCredentials({});
        window.location.href = ROUTES.LOGIN;
      },
      onError: (error) => {
        toast({
          title: 'Error Occured',
          description: 'An error occured while signing out, please try again later',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
    })
    const toast = useToast();


    useEffect(()=>{
      if (userCredentails._id) {
        ApiService.getUser(userCredentails._id).then((response) => {
          console.log("User Data",response);
          if(response.currentUser !==null){
          setUserCredentials(response.currentUser);
          const token = JSON.parse(localStorage.getItem("user")).token;
          localStorage.setItem('user', JSON.stringify({...response.currentUser, token, _id:response.currentUser.id}));
          }
        })
      }
    },[userCredentails._id]);
    return (
      <Box pos='fixed' w='100%' zIndex={100}>
        <Flex
          bg='#232527'
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 4 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('#232527')}
          align={'center'}
          >
          <Flex
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', md: 'none' }}
           >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? <CloseIcon w={3} h={3}  color='gray.200'/> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
              color='gray.200'
            />
          </Flex>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <VStack alignItems={'start'}>
              <Link href='/'>
                <Image
                  textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                  src="/fullLogo.png"
                  alt="Omia Logo"
                  width={'6rem'}
                  />
              </Link>
            </VStack>
            <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
              <DesktopNav />
            </Flex>
          </Flex>

          <VStack alignItems='flex-end'>
              <HStack justifyContent='flex-end' flexWrap='wrap' gap={2}>
              {/* { userCredentails && userCredentails._id && <Button
                  as={'a'}
                  fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4}
                  onClick={() => window.location.href = ROUTES.DASHBOARD}
                  >
                  Dashboard
                </Button>
                } */}

              </HStack>
                  <Menu >
                        <MenuButton as={Box} p="0" _hover={{ cursor: 'pointer' }}>
                            <HStack d="flex" p="2" alignItems="center" borderRadius="lg">
                                <Avatar name={ `Admin ${userCredentails.email.split('@')[0]}`} src="" size="sm" />
                                <Stack spacing="2px" ml="2">
                                    <Text fontSize="xs" fontWeight="600"> {userCredentails.email.split('@')[0]}</Text>
                                    {/* <Text fontSize="xs" color="textColor.gray">{'#' + userCredentails._id?? 'loading'}</Text> */}
                                </Stack>
                                <Spacer />
                                <Icon ml="8" as={BiChevronDown} boxSize={6} color="#B5BACC" />
                            </HStack>
                        </MenuButton>
                        <MenuList mr="12">
                            {/* <MenuDivider /> */}
                                <MenuItem icon={<VscSignOut size="16" />} fontSize="sm" value="signout" as="button" onClick={()=>mutate()}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>  
              
          </VStack>
        </Flex>
  
        {/* <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse> */}
      </Box>
    );
  }
  
  const DesktopNav = () => {
  
    return (
      <Stack direction={'row'} spacing={4}>
        {/* {NAV_ITEMS.map((navItem) => (
          <Box key={navItem.label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Link
                  p={2}
                  href={navItem.href ?? '#'}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
  
              {navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}>
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          </Box>
        ))} */}
      </Stack>
    );
  };
  
  
  const MobileNav = () => {
    return (
      <Stack
        p={4}
        display={{ md: 'none' }}
        minH="100vh" bg='#232527'>
        {/* {NAV_ITEMS.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))} */}
        <SidebarContent/>
      </Stack>
    );
  };
  
  // const MobileNavItem = ({ label, children, href }) => {
  //   const { isOpen, onToggle } = useDisclosure();
  
  //   return (
  //     <Stack spacing={4} onClick={children && onToggle}>
  //       <Flex
  //         py={2}
  //         as={Link}
  //         href={href ?? '#'}
  //         justify={'space-between'}
  //         align={'center'}
  //         _hover={{
  //           textDecoration: 'none',
  //         }}>
  //         <Text
  //           fontWeight={600}
  //           color={useColorModeValue('gray.600', 'gray.200')}>
  //           {label}
  //         </Text>
  //         {children && (
  //           <Icon
  //             as={ChevronDownIcon}
  //             transition={'all .25s ease-in-out'}
  //             transform={isOpen ? 'rotate(180deg)' : ''}
  //             w={6}
  //             h={6}
  //           />
  //         )}
  //       </Flex>
  
  //       <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
  //         <Stack
  //           mt={2}
  //           pl={4}
  //           borderLeft={1}
  //           borderStyle={'solid'}
  //           borderColor={useColorModeValue('gray.200', 'gray.700')}
  //           align={'start'}>
  //           {children &&
  //             children.map((child) => (
  //               <Link key={child.label} py={2} href={child.href}>
  //                 {child.label}
  //               </Link>
  //             ))}
  //         </Stack>
  //       </Collapse>
  //     </Stack>
  //   );
  // };
  
  
  // const NAV_ITEMS= [
  //   {
  //     label: 'Inspiration',
  //     children: [
  //       {
  //         label: 'Explore Design Work',
  //         subLabel: 'Trending Design to inspire you',
  //         href: '#',
  //       },
  //       {
  //         label: 'New & Noteworthy',
  //         subLabel: 'Up-and-coming Designers',
  //         href: '#',
  //       },
  //     ],
  //   },
  //   {
  //     label: 'Find Work',
  //     children: [
  //       {
  //         label: 'Job Board',
  //         subLabel: 'Find your dream design job',
  //         href: '#',
  //       },
  //       {
  //         label: 'Freelance Projects',
  //         subLabel: 'An exclusive list for contract work',
  //         href: '#',
  //       },
  //     ],
  //   },


  // ];