import { Box, HStack, Icon,Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { BiGridAlt, BiHome, BiReceipt, BiUser } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../../utils/constants/routes';

const Footer = () => {
    return (
        <Box position='fixed' bottom='0' bgColor='#232527'  color={'gray.200'} width='100%'>
            <HStack justifyContent='space-around'>
                <NavLink to='/' exact>
                    <VStack alignItems='center' px={4} py={1}>
                        <Icon as={BiHome} fontSize={18}/>
                        <Text>Home</Text>
                    </VStack>
                </NavLink>
                <NavLink to={ROUTES.BET_SLIPS}>
                    <VStack alignItems='center' px={4} py={1}>
                        <Icon as={BiReceipt} fontSize={18}/>
                        <Text fontSize='sm' whiteSpace='nowrap'>Bets Slips</Text>
                    </VStack>
                </NavLink>
                <NavLink to={ROUTES.DASHBOARD}>
                    <VStack alignItems='center' px={4} py={1}>
                        <Icon as={BiGridAlt} fontSize={18}/>
                        <Text fontSize='sm'>Dashboard</Text>
                    </VStack>
                </NavLink>
                <NavLink to={ROUTES.PROFILE}>
                    <VStack alignItems='center' px={4} py={1}>
                        <Icon as={BiUser}  fontSize={18}/>
                        <Text fontSize='sm'>Profile</Text>
                    </VStack>
                </NavLink>
            </HStack>
        </Box>
    );
}

export default Footer;
