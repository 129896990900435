import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { MainLayout } from './layout';
import { Home } from './views';
import * as ROUTES from './utils/constants/routes';
import LeagueGames from './views/league/league';
import SignupCard from './views/auth/register';
import Deposit from './views/deposit/deposit';
import DepositForm from './views/deposit/deposit_form';
import WithdrawForm from './views/deposit/withdraw_form';
import SigninCard from './views/auth/login';
import WalletScreen from './views/deposit/wallet';
import DashboardLayout from './layout/dashboard';
import Dashboard from './views/dashboard/dashboard';
import OrderHistory from './views/dashboard/order_history';
import Transactions from './views/dashboard/transactions';
import Deposits from './views/dashboard/products';
import Withdrawals from './views/dashboard/withdrawals';
import Refer from './views/dashboard/refer';
import ContactUs from './views/contact/contact';
import Profile from './views/profile';
import { BetSlipsMobile } from './views/betslips/bet_slips';
import Commision from './views/dashboard/commision_logs';
import Products from './views/dashboard/products';
import AddProductForm from './components/dashboard/add_product';
import AddServiceForm from './components/dashboard/add_service';
import UserList from './views/dashboard/users_list';
import EditProductForm from './components/dashboard/edit_product';
import EditServiceForm from './components/dashboard/edit_service';
import OrderDetails from './views/dashboard/order_details';


const AppRouter = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.LANDING}
        element={<Navigate to={ROUTES.LOGIN} replace={true}/>} />

      <Route
        path={ROUTES.LEAGUE_GAMES}
        element={
          <RouteWithLayout
            element={LeagueGames}
            layout={MainLayout} />
        } />
      <Route
        path={ROUTES.LOGIN} element={<SigninCard />}
      />
      <Route
        path={ROUTES.CONTACT_US} element={<ContactUs />}

      />
      <Route
        path={ROUTES.DEPOSIT_AND_WITHDRAW}
        element={
          <RouteWithLayout
            element={Deposit}
            layout={MainLayout} />
        } />
      <Route
        path={'/app/deposit/:id'}
        element={
          <RouteWithLayout
            element={DepositForm}
            layout={MainLayout} />
        } />
      <Route
        path={ROUTES.DEPOSIT_FUNDS_WAITING}
        element={
          <RouteWithLayout
            element={WalletScreen}
            layout={MainLayout} />
        } />
      <Route
        path={'/app/withdraw/:id'}
        element={
          <RouteWithLayout
            element={WithdrawForm}
            layout={MainLayout} />
        } />
      <Route
        exact
        path={ROUTES.DASHBOARD}

        element={
          <RouteWithLayout
            element={Dashboard}
            layout={DashboardLayout} />
        } />
      <Route
        exact
        path={ROUTES.DASHBOARD_ORDERS}

        element={
          <RouteWithLayout
            element={OrderHistory}
            layout={DashboardLayout} />
        } />
      <Route
        exact
        path={ROUTES.DASHBOARD_TRANSACTIONS}

        element={
          <RouteWithLayout
            element={Transactions}
            layout={DashboardLayout} />
        } />
      <Route
        path={ROUTES.DASHBOARD_PRODUCTS}
        element={
          <RouteWithLayout
            element={Products}
            layout={DashboardLayout} />
        } />
        <Route
        path={ROUTES.DASHBOARD_ADD_PRODUCTS}
        element={
          <RouteWithLayout
            element={AddProductForm}
            layout={DashboardLayout} />
        } />
         <Route
        path={ROUTES.DASHBOARD_ADD_PRODUCTS}
        element={
          <RouteWithLayout
            element={AddProductForm}
            layout={DashboardLayout} />
        } />
         <Route
        path={ROUTES.DASHBOARD_EDIT_PRODUCTS}
        element={
          <RouteWithLayout
            element={EditProductForm}
            layout={DashboardLayout} />
        } />
      <Route
        path={ROUTES.DASHBOARD_WITHDRAWALS}
        element={
          <RouteWithLayout
            element={Withdrawals}
            layout={DashboardLayout} />
        } />
         <Route
        path={ROUTES.DASHBOARD_ADD_SERVICE}
        element={
          <RouteWithLayout
            element={AddServiceForm}
            layout={DashboardLayout} />
        } />
         <Route
        path={ROUTES.DASHBOARD_EDIT_SERVICE}
        element={
          <RouteWithLayout
            element={EditServiceForm}
            layout={DashboardLayout} />
        } />
      <Route
        path={ROUTES.DASHBOARD_REFERRAL}
        element={
          <RouteWithLayout
            element={Refer}
            layout={DashboardLayout} />
        } />
        <Route
        path={ROUTES.DASHBOARD_USERS}
        element={
          <RouteWithLayout
            element={UserList}
            layout={DashboardLayout} />
        } />
      <Route
        path={ROUTES.DASHBOARD_TRASH}
        element={
          <RouteWithLayout
            element={Commision}
            layout={DashboardLayout} />
        } />
        <Route
        path={ROUTES.DASHBOARD_ORDER_DETAILS}
        element={
          <RouteWithLayout
            element={OrderDetails}
            layout={DashboardLayout} />
        } />
      <Route
        exact
        path={ROUTES.BET_SLIPS}

        element={
          <RouteWithLayout
            element={BetSlipsMobile}
            layout={MainLayout} />
        } />

      <Route
        path={ROUTES.PROFILE}
        element={
          <RouteWithLayout
            element={Profile}
            layout={DashboardLayout} />
        } />
    </Routes>
  );
}

export default AppRouter;
