import React from 'react';
import {
    Text, HStack, VStack, Spacer, Badge,
    AccordionPanel, AccordionIcon, AccordionButton, Accordion, AccordionItem, Image, Button, Link,
    useToast, useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes'
import ApiService from '../../services/api_service';
import { useMutation } from 'react-query';
const WithdrawalCard = ({ service, forTrash=false }) => {
    const navigate  = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [loading, setLoading] = React.useState(false)

    const { mutate, isError, isLoading, isSuccess, error, data } = useMutation(
        () => ApiService.deleteService(service),
        {
            onSuccess: () => {
                onClose();
                setLoading(false);
                toast(
                    {
                        title: "Service Deleted",
                        description: "Service deleted Successfully.",
                        status: "success",
                        duration: 5000,
                    }
                )
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
                onClose();
                setLoading(false);
            }

        }
    )
    const { mutate: restore, isLoading:isLoadingRestore } = useMutation(
        () => ApiService.restoreService(service),
        {
            onSuccess: () => {
                // toast(
                //     {
                //         title: "Product Updated",
                //         description: "Product has been updated successfully",
                //         status: "success",
                //         duration: 5000,
                //     }
                // )
                onClose();
                setLoading(false);
                
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
                onClose();
                setLoading(false);
            }

        }
    )
    const handleDelete = () => {
        setLoading(true);
        mutate();
    }
    return (
        <VStack w='22%' borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' m={4}>
            <Image src={service.img}  alt={service.title} w='100%' h='200px' objectFit='cover' />
            <Text as='b'>{service.title}</Text>
            <Text>Description</Text>
            <Text textAlign='center' noOfLines={1}>{service.description}</Text>
            <HStack w='100%'>
                <Text>Category</Text>
                <Spacer />
                <Text>{service.category}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Guide</Text>
                <Spacer />
                <Link  color='blue.400' isExternal={true}   href={service.guide}>Read guide</Link>
            </HStack>
            <HStack w='100%'>
                <Text>Created At</Text>
                <Spacer />
                <Text>{moment(service.createdAt).format('MMMM Do YYYY, h:mm')}</Text>
            </HStack>
            <Accordion allowMultiple w='100%'>
                <AccordionItem border='none' w='100%'>
                    <AccordionButton w='100%' d='flex' bgColor='#F56403'>
                        <Text>
                            Actions
                        </Text>
                        <Spacer />
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        {!forTrash&&(<HStack justifyContent="center" w='100%'>
                            <Button w='100%' onClick={handleDelete}
                                bg="red.400"
                                color="white" px={8}>
                                Delete Service
                            </Button>
                            <Button w='100%' onClick={() => {
                                navigate(ROUTES.DASHBOARD_EDIT_SERVICE,{state:{service:{...service}}})
                             }}
                                bg="blue.400"
                                color="white" px="25">
                                Edit Service
                            </Button>

                        </HStack>)}
                        {
                            forTrash&&(<HStack justifyContent="center" w='100%'>
                                <Button w='100%' onClick={restore} isLoading={isLoadingRestore}
                                    colorScheme='yellow'
                                     px={8}>
                                    Restore Service
                                </Button>
                            </HStack>)
                        }
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Service
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={handleDelete} ml={3} isLoading={isLoading || loading}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </VStack>
    );
};

export default WithdrawalCard;