/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
} from '@chakra-ui/react';
import {
FiSearch,
} from 'react-icons/fi';
import ApiService from '../../../../services/api_service';
import { useQuery } from 'react-query';
import { NavLink } from 'react-router-dom';
import {BsArrowRight} from 'react-icons/bs'

export default function SimpleSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg='#232527'>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>
        {children}
      </Box>
    </Box>
  );
}


export const SidebarContent = ({ onClose, ...rest }) => {
      const [searchedLeagues, setSearchedLeagues] = React.useState([]);
      const {
        data, isLoading, isError, error,
      } = useQuery('leagues', ApiService.getLeagues,{
        onSuccess: (data) => {
          setSearchedLeagues(data.sort((a, b) => a.name.localeCompare(b.name)));
          console.log("Data set successfully")
        }
      });
      if (isLoading) {
        return <p>Loading...</p>;
      }
      if (isError) return <p>{error.message}</p>;
      const onSearch = (e) => {
        const search = e.target.value;
        console.log("Search text is: ",search);
        if (search) {
          const filteredLeagues = data.filter((league) => league.name.toLowerCase().includes(search.toLowerCase()));
          setSearchedLeagues(filteredLeagues);
        } else {
          setSearchedLeagues(data);
        }
      }
  return (
    <Box
      bg='#2B2E31'
      borderRight="1px"
      borderRightColor={useColorModeValue('#2B2E31')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY='scroll'
      {...rest}>
      <Box p={4}>
       <InputGroup>
            <InputLeftElement
                    pointerEvents='none'
                    children={<Flex align='center' justify='center'><Icon as={FiSearch} color='gray.100' mb={2} /></Flex>}
                />
            <Input placeholder='Search League' size='sm' color='gray.200' onChange={onSearch} />
       </InputGroup>
      </Box>
      <Box  >
      {searchedLeagues.map((league) => (
        <NavItem key={league.id} icon={league.icon} id={league.id}>
          <Text size='xs' fontSize="14px" color='gray.100'>{league.name}</Text>
          <Spacer/>
          <Icon as={BsArrowRight} color='gray.200'/>
        </NavItem>
      ))}
      </Box>
    </Box>
  );
};

const NavItem = ({ id,icon, children, ...rest }) => {
  return (
    <NavLink  to={`/leagues/${id}`} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        py='3'
        mx="0"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Image
            mr="4"
            boxSize='20px'
            _groupHover={{
              color: 'white',
            }}
            src={icon}
          />
        )}
        {children}
      </Flex>
    </NavLink>
  );
};


const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>

    </Flex>
  );
};