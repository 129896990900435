import { AddIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Divider, Text,Input, HStack, Button, useToast,ButtonGroup,IconButton } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReferalLevelList from '../../components/dashboard/refferal_level_list';
import * as ROUTES from '../../utils/constants/routes'

const Refer = () => {
    const user = localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {}
    const navigate = useNavigate();
    const toast = useToast();
    const [level, setLevel] = React.useState(1);
    if(!user.username){
        navigate(ROUTES.LOGIN)
    }
    return (
        <Box p={6} minH='100vh'>
            <Text>Referral Link</Text>
            <Divider color='gray.400' my={2}/>
            <Text fontSize='sm'>Automatically top up your account balance by sharing your referral link, Earn a percentage of whatever plan your referred user buys.</Text>

            <Box p={8} border='1px solid #f4f4f4' my={8} borderRadius={2}>
                <HStack>
                    <Input value={`${window.location.origin}/auth/register/${user.username}`} />
                    <Button  leftIcon={<CopyIcon />}  cursor='pointer' bgColor='#F56403' fontSize='sm'
                    onClick={()=>{
                            navigator.clipboard.writeText(`${window.location.origin}/auth/register/${user.username}`);
                            toast({
                                    title: "Copied to clipboard",
                                    description:  'Referral Link copied to clipboard',
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                            });
                        }}>Copy</Button>
                </HStack>
            </Box>
            <Box mt={4}>
                    <HStack>
                        <ButtonGroup size='sm' isAttached variant='outline' _hover={{
                            color: '#F56403',
                        }} bgColor={level=== 1?'#F56403':'transparent'} borderRadius='md'>
                            <Button onClick={()=>setLevel(1)}>Level 1</Button>
                            <IconButton aria-label='Add to friends' icon={<AddIcon />} />
                        </ButtonGroup>
                        <ButtonGroup size='sm' isAttached variant='outline' _hover={{
                            color: '#F56403',
                        }} bgColor={level=== 2?'#F56403':'transparent'} borderRadius='md'>
                            <Button  onClick={()=>setLevel(2) }>Level 2</Button>
                            <IconButton aria-label='Add to friends' icon={<AddIcon />} />
                        </ButtonGroup>
                        <ButtonGroup size='sm' isAttached variant='outline' _hover={{
                            color: '#F56403',
                        }} bgColor={level=== 3?'#F56403':'transparent'} borderRadius='md'>
                            <Button onClick={()=>setLevel(3) }>Level 3</Button>
                            <IconButton aria-label='Add to friends' icon={<AddIcon />} />
                        </ButtonGroup>
                    </HStack>
                    <ReferalLevelList level={level}/>
            </Box>
        </Box>
    );
};

export default Refer;