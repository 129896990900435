import React from 'react';
import {
    Text, HStack, VStack, Spacer, Badge,
    AccordionPanel, AccordionIcon, AccordionButton, Accordion, AccordionItem, Image, Button, useToast, useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
} from '@chakra-ui/react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes';
import ApiService from '../../services/api_service';
import { useMutation } from 'react-query';
import { DeleteIcon } from '@chakra-ui/icons';
import { BiPencil } from 'react-icons/bi';
const DepositCard = ({ deposit, forTrash = false }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [loading, setLoading] = React.useState(false);

    const { mutate, isError, isLoading, isSuccess, error, data } = useMutation(
        () => ApiService.deleteProduct(deposit),
        {
            onSuccess: () => {
                // toast(
                //     {
                //         title: "Product Updated",
                //         description: "Product has been updated successfully",
                //         status: "success",
                //         duration: 5000,
                //     }
                // )
                onClose();
                setLoading(false);
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
                onClose();
                setLoading(false);
            }

        }
    )
    const { mutate: restore, isLoading:isLoadingRestore } = useMutation(
        () => ApiService.restoreProduct(deposit),
        {
            onSuccess: () => {
                // toast(
                //     {
                //         title: "Product Updated",
                //         description: "Product has been updated successfully",
                //         status: "success",
                //         duration: 5000,
                //     }
                // )
                onClose();
                setLoading(false);
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
                onClose();
                setLoading(false);
            }

        }
    )
    const handleDelete = () => {
        setLoading(true);
        mutate();
    }
    return (
        <VStack w='23.5%' borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' m={2}>
            <Image src={deposit.img} alt={deposit.title} w='100%' h='200px' objectFit='cover' />
            <Text as='b'>{deposit.title}</Text>
            <Text>Description</Text>
            <Text textAlign='center' noOfLines={1}>{deposit.description}</Text>
            <HStack w='100%'>
                <Text>Price</Text>
                <Spacer />
                <Text>UGX {parseFloat(deposit.price).toLocaleString()}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Category</Text>
                <Spacer />
                <Text>{deposit.category}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Available</Text>
                <Spacer />
                {
                    deposit.available ? <Badge colorScheme='green' textTransform='capitalize' px={2} variant='solid' >Available</Badge> :
                        <Badge colorScheme='red' textTransform='capitalize' px={2} variant='solid' >Out of Stock</Badge>
                }
            </HStack>
            <HStack w='100%'>
                <Text>Created</Text>
                <Spacer />
                <Text>{moment(deposit.createdAt).format('MMMM Do YYYY, h:mm a')}</Text>
            </HStack>
            <Accordion allowMultiple w='100%'>
                <AccordionItem border='none' w='100%'>
                    <AccordionButton w='100%' d='flex' bgColor='#F56403'>
                        <Text>
                            More Information
                        </Text>
                        <Spacer />
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <VStack alignItems='center'>
                            <HStack w='100%'>
                                <Text>Main Category</Text>
                                <Spacer />
                                <Text>{deposit.grandCategory}</Text>
                            </HStack>
                            <HStack w='100%'>
                                <Text>In Stock</Text>
                                <Spacer />
                                <Text>{parseFloat(deposit.avaliableQuantity).toLocaleString()}</Text>
                            </HStack>

                            <HStack w='100%'>
                                <Text>sizes</Text>
                                <Spacer />
                                {
                                    deposit.sizes.map((size) => <Badge colorScheme='green' textTransform='capitalize' px={2} variant='solid' >{ size.size ? size.size: size}</Badge>)
                                }
                            </HStack>
                            {!forTrash && <HStack w='100%'>
                                <Button w='100%' 
                                onClick={onOpen}
                                    colorScheme='red'
                                    leftIcon={<DeleteIcon/>}>
                                    Delete 
                                </Button>
                                <Button w='100%' onClick={() => {
                                    navigate(ROUTES.DASHBOARD_EDIT_PRODUCTS, { state: { product: { ...deposit } } });
                                }}
                                    colorScheme='blue'
                                    leftIcon={<BiPencil/>}
                                >
                                    Edit 
                                </Button>
                            </HStack>}
                            {
                                forTrash && (<Button w='100%' onClick={restore} isLoading={isLoadingRestore}
                                    colorScheme='yellow'
                                >
                                    Restore 
                                </Button>)
                            }

                        </VStack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Product
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={handleDelete} ml={3} isLoading={isLoading || loading}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </VStack>
    );
};

export default DepositCard;