import React,{useState} from 'react';
import { Box, Input, Text,HStack,FormControl,FormLabel,Spacer, VStack,Icon, Divider,useToast } from '@chakra-ui/react';
import { Link,useNavigate } from 'react-router-dom';

import {BsArrowRight} from 'react-icons/bs'
import * as ROUTES from '../../utils/constants/routes';

const Profile = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();

    if(!userCredentails.id){
        toast({
            title: "Authentication Required",
            description: 'Login in to continue with this action ',
            status: "warning",
            duration: 9000,
            isClosable: true,
        });
        navigate(ROUTES.LOGIN);
    }
    return (
        <Box  p={8} w='100%' minH={'100vh'} pt={2}>
            <HStack>
                <Text>Balance: {parseFloat(userCredentails.balance).toFixed(2)} USDT</Text>
                <Spacer/>
                {/* <Text color='blue.400'>Edit</Text> */}
            </HStack>
            <HStack my='8'>
                <FormControl>
                    <FormLabel>First Name</FormLabel>
                    <Input type='text' value={userCredentails.firstname} />
                </FormControl>
                <Spacer/>
                <FormControl>
                    <FormLabel>Last Name</FormLabel>
                    <Input type='text' value={userCredentails.lastname} />
                </FormControl>
            </HStack>
            <HStack my='4'>
                <FormControl>
                    <FormLabel>User Name</FormLabel>
                    <Input type='text' value={userCredentails.username} />
                </FormControl>
                <Spacer/>
                <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input type='text' value={userCredentails.mobile} />
                </FormControl>
            </HStack>
            <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type='text' value={userCredentails.email} />
            </FormControl>

            <Text mt={12} color='#F56403'>More details</Text>
            <Divider my={2}/>

            <VStack alignItems='flex-start' pt={2}>
            <Link style={{
                    width: '100%',
                }} to={ROUTES.DASHBOARD_ORDERS}>
                    <HStack alignItems='center' borderBottom='1px solid #F56403 ' pb='2'>
                     <Text  color='blue.400'>Investments</Text>
                        <Spacer/>
                        <Icon as={BsArrowRight} color='blue.400'/>
                    </HStack>
                </Link>
                <Link style={{
                    width: '100%',
                }} to={ROUTES.DASHBOARD_PRODUCTS}>
                    <HStack alignItems='center' borderBottom='1px solid #F56403 ' pb='2'>
                     <Text  color='blue.400'>Products</Text>
                        <Spacer/>
                        <Icon as={BsArrowRight} color='blue.400'/>
                    </HStack>
                </Link>
                <Link style={{
                    width: '100%',
                }} to={ROUTES.DASHBOARD_WITHDRAWALS}>
                   
                    <HStack alignItems='center' borderBottom='1px solid #F56403 ' py='2'>
                     <Text  color='blue.400'>Withdrawals</Text>
                        <Spacer/>
                        <Icon as={BsArrowRight} color='blue.400'/>
                    </HStack>
                 
                </Link>
                <Link style={{
                    width: '100%',
                }} to={ROUTES.DASHBOARD_TRANSACTIONS}>
                   
                    <HStack alignItems='center' borderBottom='1px solid #F56403 ' py='2'>
                     <Text  color='blue.400'>Transactions</Text>
                        <Spacer/>
                        <Icon as={BsArrowRight} color='blue.400'/>
                    </HStack>
                </Link>
                <Link style={{
                    width: '100%',
                }} to={ROUTES.DASHBOARD_REFERRAL}>
                
                    <HStack alignItems='center' borderBottom='1px solid #F56403 ' py='2'>
                     <Text  color='blue.400'>Referrals</Text>
                        <Spacer/>
                        <Icon as={BsArrowRight} color='blue.400'/>
                    </HStack>
                </Link>
            </VStack>
        </Box>
    );
};

export default Profile;