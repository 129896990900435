import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Select, Flex, Image, useToast, HStack, Text, VStack, IconButton } from '@chakra-ui/react';
import { CATEGORIES, GRAND_CATEGORIES, MEASUREMENT_UNITS, SUB_CATEGORIES } from '../../utils/constants';
import { useMutation } from 'react-query';
import ApiService from '../../services/api_service';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes'
import { uploadImage } from '../../services/FileUploadService';
import { MdKeyboardBackspace } from "react-icons/md";
import { DeleteIcon } from '@chakra-ui/icons';

const AddProductForm = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState([]);
    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');
    const [quantity, setQuantity] = useState('');
    const [sizes, setSizes] = useState([{ size: '', price: '' }]);
    const [measurementUnit, setMeasurementUnit] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState([]);
    const [loading, setLoading] = useState(false);
    const [manufacturer, setManufacturer] = useState('');


    const navigate = useNavigate();
    const toast = useToast();
    const { mutate, isError, isLoading, isSuccess, error, data } = useMutation(
        (img) => ApiService.addProducts({
            title: name,
            description,
            price: sizes[0].price,
            avaliableQuantity: quantity,
            category: subcategory,
            grandCategory: category,
            manufacturer,
            img,
            color: ['transparent'],
            sizes: sizes.map((size) => ({ size: `${size.size} ${measurementUnit}`, price: size.price })),
        }),
        {
            onSuccess: () => {
                toast(
                    {
                        title: "Product Added",
                        description: "Product has been added successfully",
                        status: "success",
                        duration: 5000,
                    }
                )
                navigate(ROUTES.DASHBOARD_PRODUCTS);
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
            }

        }
    )


    const handleSizeChange = (e, index, field) => {
        const newSizes = [...sizes];
        newSizes[index][field] = e.target.value;
        setSizes(newSizes);
    };

    const addSize = () => {
        setSizes([...sizes, { size: '', price: '' }]);
    };

    const removeSize = (index) => {
        const newSizes = [...sizes];
        newSizes.splice(index, 1);
        setSizes(newSizes);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !description || !image.length === 0 || !category || !subcategory || !quantity || sizes.length === 0 || !measurementUnit) {
            console.log('All fields are required!');
            return;
        }
        if (image.length > 0 && imagePreviewUrl.length > 0) {
            setLoading(true)
            const imagesURLS = []
            for (let i = 0; i < image.length; i++) {
                // Upload Image to Firebase
                const imageURl = await uploadImage(imagePreviewUrl[i]);
                imagesURLS.push(imageURl)
            }
            setLoading(false)
            mutate(imagesURLS);
        }
        // Continue with form submission logic
    };

    const resetForm = () => {
        setName('');
        setDescription('');
        setPrice('');
        setImage([]);
        setCategory('');
        setSubcategory('');
        setQuantity('');
        setSizes([]);
        setMeasurementUnit('');
        setImagePreviewUrl([]);
        setManufacturer('');
    };
    return (
        <Box w='90vw' maxH='100vh'>
            <HStack ml={8} mb={8}>
                <Button leftIcon={<MdKeyboardBackspace />} variant='link' colorScheme='blue'
                    onClick={() => navigate(ROUTES.DASHBOARD_PRODUCTS)}>
                    Product List
                </Button>
            </HStack>
            <form onSubmit={handleSubmit} style={{
                width: '100%',
                padding: '0 20px',
                borderRadius: '5px',
            }} >
                <HStack alignItems="flex-start" gap='20' justifyContent="center" >
                    <VStack w='30vw' alignItems='flex-start'>
                        <Text>Description</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius: 8
                        }} w='100%'>
                            <FormControl id="name" marginBottom="4" isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter product name"
                                />
                            </FormControl>
                            <FormControl id="description" marginBottom="4" isRequired>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter product description"
                                />
                            </FormControl>
                        </Box>
                        <Text>Category</Text>

                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius: 8

                        }} w='100%'>
                            <FormControl id="category" marginBottom="4" isRequired>
                                <FormLabel>Category</FormLabel>
                                <Select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    placeholder="Select category"
                                    sx={{
                                        '> option': {
                                            background: 'black',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    {
                                        GRAND_CATEGORIES.map((category) => <option style={{ color: 'white', backgroundColor: 'black' }} value={category}>{category}</option>)
                                    }

                                </Select>
                            </FormControl>
                            {category && (<FormControl id="subcategory" marginBottom="4" isRequired>
                                <FormLabel>Subcategory</FormLabel>
                                <Select
                                    value={subcategory}
                                    onChange={(e) => setSubcategory(e.target.value)}
                                    placeholder="Select subcategory"
                                    sx={{
                                        '> option': {
                                            background: 'black',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    {
                                        SUB_CATEGORIES[category].map((category) => <option value={category}>{category}</option>)
                                    }
                                </Select>
                            </FormControl>)}
                        </Box>
                        <Text>Inventory</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius: 8

                        }} w='100%'>
                            <FormControl id="quantity" marginBottom="4" isRequired>
                                <FormLabel>Quantity</FormLabel>
                                <Input
                                    type="number"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Enter quantity"
                                />
                            </FormControl>


                            <FormControl id="measurementUnit" marginBottom="4" isRequired>
                                <FormLabel>Measurement Unit</FormLabel>
                                <Select
                                    value={measurementUnit}
                                    onChange={(e) => setMeasurementUnit(e.target.value)}
                                    placeholder="Select measurement unit"
                                >
                                {
                                    MEASUREMENT_UNITS.map((unit) => <option value={unit} key={unit}>{unit}</option>)
                                }
                                    {/* <option value="Ltr">Ltr</option>
                                    <option value="Kg">Kg</option> */}
                                </Select>
                            </FormControl>
                            <FormControl id="manufacturer" marginBottom="4" isRequired>
                                <FormLabel>Manufacturer</FormLabel>
                                <Input
                                    type="text"
                                    value={manufacturer}
                                    onChange={(e) => setManufacturer(e.target.value)}
                                    placeholder="Enter manufacturer"
                                />
                            </FormControl>
                        </Box>
                    </VStack>
                    <VStack w='30vw' alignItems='flex-start'>
                        <Text>Product Images</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius: 8
                        }} w='100%'>
                            <FormControl id="image" marginBottom="4" isRequired>
                                <FormLabel>Image</FormLabel>
                                <Input
                                    type="file"
                                    multiple
                                    onChange={(e) => {
                                        setImage([...e.target.files]);
                                        setImagePreviewUrl([...e.target.files].map(file => URL.createObjectURL(file)));
                                    }}
                                />
                                <Flex flexDirection='row'>
                                    {imagePreviewUrl.length > 0 && imagePreviewUrl.map((url, index) => (
                                        <Box key={index} marginY={2} >
                                            <Image src={url} alt={`Image preview ${index + 1}`} boxSize="200px" />
                                        </Box>
                                    ))}
                                </Flex>
                                {/* {imagePreviewUrl && <Image src={imagePreviewUrl} alt="Image preview" boxSize="200px" />} */}
                            </FormControl>
                        </Box>
                        <Text>Pricing</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius: 8
                        }} w='100%'>
                            <FormControl id="sizes" marginBottom="4" isRequired>
                                <FormLabel>Sizes</FormLabel>
                                {sizes.map((sizeObj, index) => (
                                    <Flex key={index} marginBottom="2">
                                        <Input
                                            type="text"
                                            value={sizeObj.size}
                                            onChange={(e) => handleSizeChange(e, index, 'size')}
                                            placeholder="Enter size"
                                        />
                                        <Input
                                            type="number"
                                            value={sizeObj.price}
                                            onChange={(e) => handleSizeChange(e, index, 'price')}
                                            placeholder="Enter price"
                                            marginLeft="2"
                                        />
                                        <IconButton onClick={() => removeSize(index)} marginLeft="2" bgColor='#F56403' color={'white'}
                                            aria-label="Remove Size" icon={<DeleteIcon />}
                                        />
                                    </Flex>
                                ))}
                                <Button bgColor='#F56403' color={'white'} onClick={addSize} >Add Size</Button>
                            </FormControl>
                        </Box>
                        <HStack w='100%' justifyContent="flex-end" pt={4}>
                            <Button onClick={resetForm} bgColor="gray.200" marginRight="4" paddingX={8} color='black'>Reset</Button>
                            <Button type="submit" colorScheme="green" isLoading={loading || isLoading} paddingX={24}>Add Product</Button>
                        </HStack>
                    </VStack>
                </HStack>
            </form>
        </Box>
    );
};

export default AddProductForm;