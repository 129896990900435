import React,{useEffect,useState} from 'react';
import { Box,Text,Flex, HStack, Image, Spacer, Button,Hide, Show, VStack } from '@chakra-ui/react';
import ApiService from '../../services/api_service';

const MatchCard = ({match,setSlips}) => {
    const teams = match.name.split(' Vs ');
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ?{...JSON.parse(localStorage.getItem('user'))}: {});
    let vip_level = '0';
    if (userCredentails.id) {
        if (userCredentails.balance >= 100){
            vip_level = '1';
        }
        if (userCredentails.balance  >=4999) {
              vip_level = '2';
       }
    }
    const question = match.questions.filter((question) =>  question.vip_level ===  vip_level)[0];
    const addToSlip = ({option,question,match}) => {
       const slips =  localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): [];
       localStorage.setItem('slip',JSON.stringify([...slips,{option,question,match}]));
       setSlips([...slips,{option,question,match}]);
    }

    useEffect(()=>{
        if (userCredentails.id) {
          ApiService.getUser(userCredentails.id).then((response) => {
            setUserCredentials(response.user);
            const  token = JSON.parse(localStorage.getItem("user")).token;
            localStorage.setItem('user', JSON.stringify({ ...response.user, token }));
          })
        }
      },[userCredentails.id]);
    return (
        <Box p={4} shadow='md' maxW={'100%'}>
            <Hide below='md'>
                <Flex alignItems='center' >
                <Hide below='md'>
                    <Text fontSize='14px'>Vs</Text>
                </Hide>
                    <Box textAlign='center'>
                        <HStack spacing={3} >
                            <Image src={match.home_logo} alt={teams[0]}   boxSize='30px' objectFit='cover'/>
                            <Text fontSize='14px'>{teams[0]}</Text>
                        </HStack>
                        <Show below='md'>
                            <Text fontSize='14px'>VS</Text>
                        </Show>
                        <HStack spacing={3} mt={3}>
                            <Image src={match.away_logo} alt={teams[1]} boxSize='30px' objectFit='cover' />
                            <Text fontSize='14px'>{teams[1]}</Text>
                        </HStack>
                    </Box>
                    <Spacer/>
                    <Box>
                        <HStack mt={3} flexWrap='wrap' ml={8}>
                        {
                                question.options.map((option) => {
                                    return (
                                        <VStack ml='0'>
                                            <Button fontSize='14px' variant='ghost'  disabled>{option.name}</Button>
                                            <Button fontSize='14px' variant='ghost' color='#F56403' onClick={()=>{addToSlip({option,question,match})}}>{((parseFloat(option.divisor)-1)*100).toFixed(2)}</Button>
                                        </VStack>
                                    )
                                })
                            }
                        </HStack>
                    </Box>
                </Flex>
            </Hide>
            <Show below='md'>
                <Flex alignItems='center' direction='column' >
                    <Box textAlign='center'>
                        <HStack spacing={3} >
                            <Image src={match.home_logo} alt={teams[0]}   boxSize='30px' objectFit='cover'/>
                            <Text fontSize='14px'>{teams[0]}</Text>
                        </HStack>
                        <Show below='md'>
                            <Text fontSize='14px'>VS</Text>
                        </Show>
                        <HStack spacing={3} mt={3}>
                            <Image src={match.away_logo} alt={teams[1]} boxSize='30px' objectFit='cover' />
                            <Text fontSize='14px'>{teams[1]}</Text>
                        </HStack>
                    </Box>
                    <Spacer/>
                    <Box>
                        <HStack mt={3} flexWrap='wrap' ml={8}>
                        {
                                question.options.map((option) => {
                                    return (
                                        <VStack ml='0'>
                                            <Button fontSize='14px' variant='ghost'  disabled>{option.name}</Button>
                                            <Button fontSize='14px' variant='ghost' color='#F56403' onClick={()=>{
                                                addToSlip({option,question,match})
                                                
                                                }}>{((parseFloat(option.divisor)-1)*100).toFixed(2)}</Button>
                                        </VStack>
                                    )
                                })
                            }
                        </HStack>
                    </Box>
                </Flex>
            </Show>
        </Box>
    );
};

export default MatchCard;