import React from 'react';
import { Text, HStack, VStack, Spacer, Badge, Link, } from '@chakra-ui/react';
import moment from 'moment';
const TransactionCard = ({ transaction }) => {
    return (
        <VStack  borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' m={4}>
            <HStack w='100%'>
                <Spacer />
                {
                    transaction.status === 'cancelled' ? <Badge colorScheme='red' textTransform='capitalize' px={2} variant='solid' >cancelled</Badge> :
                    transaction.status === 'successful'?  <Badge colorScheme='green' textTransform='capitalize' px={2} variant='solid' >Successful</Badge>:
                    <Badge colorScheme='gray' textTransform='capitalize' px={2} variant='solid' >{transaction.status}</Badge>
                }
            </HStack>
            <HStack w='100%'>
                <Text>Transaction Reference.</Text>
                <Spacer />
                <Text>{transaction.transactionId.split("flw_tx_ref_")[1]}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>By User</Text>
                <Spacer />
               <VStack>
                    <Link color='blue.400'>{transaction.userId.email}</Link>
               </VStack>
            </HStack>
            <HStack w='100%'>
                <Text>Amount</Text>
                <Spacer />
                <Text>UGX {parseFloat(transaction.totalAmount).toLocaleString()}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Time</Text>
                <Spacer />
                <Text>{moment(transaction.createdAt).format('MMMM Do YYYY, h:mm a')}</Text>
            </HStack>
        </VStack>
    );
};

export default TransactionCard;