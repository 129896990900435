import { Text, VStack, useToast, Box, Spinner, HStack, Flex, Spacer, Button, Input } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DepositCard from '../../components/dashboard/deposit_card';
import TransactionCard from '../../components/dashboard/transaction_card';
import ApiService from '../../services/api_service';
import * as ROUTES from '../../utils/constants/routes';
const Products = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const {
        data, isLoading, isError, error,
    } = useQuery('products', () => {
        if (!userCredentails.id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
        return ApiService.products()
    }, {
        onSuccess: () => {
            // toast({
            //     title: "Success",
            //     description: 'User Deposits Fetched  Successfully',
            //     status: "success",
            //     duration: 9000,
            //     isClosable: true,
            // });
        }
    });
    return (
        <Box p={6} minH='100vh'  w='90vw'>
            {isLoading && <Spinner />}
            {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
            {data && (
                <>

                    <HStack justifyContent='center' w='100%'>
                        <Text mt="4">All Products</Text>
                        <Spacer />
                        <Button
                            fontSize={'sm'}
                            fontWeight={600}
                            color={'white'}
                            bg={'#F56403'}
                            _hover={{
                                opacity: 0.5,
                                cursor: 'pointer'
                            }}
                            size='sm'
                            px={4} borderRadius={'2'} 
                            onClick={()=>{navigate(ROUTES.DASHBOARD_ADD_PRODUCTS)}}>Add Product</Button>
                    </HStack>
                    <Flex justifyContent="center" marginBottom="4" gap='4'>
                   <Input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search Product"
                        width='30%'
                    />
                    <Button onClick={()=>{}} colorScheme='blue' variant='solid'>Search</Button>
                   </Flex>
                    <Flex alignItems='flex-start' py="4" w="100%" flexWrap='wrap'>
                        {[...data.products].reverse().filter(product => product.title.toLowerCase().includes(searchTerm.toLowerCase())).map((deposit) => (
                            <DepositCard deposit={deposit} key={deposit._id} />
                        ))}
                    </Flex>
                </>
            )}
        </Box>
    );
};

export default Products;