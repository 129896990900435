import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    Link,
    Image,
    AlertIcon,
    Alert,
  } from '@chakra-ui/react';
  import { useState } from 'react';
  import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
  import 'react-phone-number-input/style.css'
  import { useMutation } from 'react-query';
  import ApiService from '../../services/api_service';
  import { useNavigate } from 'react-router-dom';
  import * as ROUTES from '../../utils/constants/routes';
  
  export default function SigninCard() {
    const [showPassword, setShowPassword] = useState(false);
    const [userData, setUserData] = useState({});
    const { mutate, isError, isLoading, isSuccess, error,data} = useMutation((details) => ApiService.login(details))
    const navigate = useNavigate();
  
    const onChange = (e) => {
      if (e.target.value) {
        setUserData({ ...userData, [e.target.name]: e.target.value })
      }
    }
    if (data) {
      console.log(data);
    }
    if (isSuccess && data) {
      console.log("Success Recieved: ",data);
      localStorage.setItem('user', JSON.stringify({ ...data.user._doc, token: data.user.token }));
      navigate(ROUTES.DASHBOARD)
    }

    if (error) {
      console.log(error);
    }
  
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg='#2B2E31'
        color='gray.100'
      >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Image src={'/fullLogo.png'} alt="logo"
            />
            {/* <Heading fontSize={'4xl'}>Sign in to your account</Heading> */}
          </Stack>
          <Box
            rounded={'lg'}
            boxShadow={'lg'}
            width={'md'}
            p={8}>
            {isError && error && (
              <Alert status='error' variant='solid' my={4}>
                <AlertIcon />
                { error.data && error.data.errors ?error.data.errors[0].message:'Something went wrong'}
              </Alert>
            )
            }
            {data && data.errors && (
            <Alert status='error' variant='solid' my={4}>
              <AlertIcon />
              { data.errors.join(',')}
            </Alert>
          )
          }
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email Address</FormLabel>
                <Input type="email" name="email" onChange={onChange}  />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={showPassword ? 'text' : 'password'} name='password' onChange={onChange} />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={'#20914D'}
                  color={'white'}
                  _hover={{
                    opacity: 0.8,
                  }}
                  onClick={() => {
                    mutate({ ...userData,})
                  }}
                  isLoading={isLoading}>
                  login
                </Button>
              </Stack>
              {/* <Stack pt={6}>
                <Text align={'center'}>
                  New user? <Link color={'blue.400'} href="/auth/register">Create Account</Link>
                </Text>
              </Stack> */}
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }