import React,{ useState} from 'react';
import { Flex, Box,Text,VStack,HStack, Heading, Spacer,Image, Button,useToast  } from '@chakra-ui/react';
import ApiService from '../../services/api_service';
import { useQuery } from 'react-query';
import * as ROUTES from '../../utils/constants/routes';
import { Link,useParams,useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import BetSlip from '../../components/home/bet_slip';
import Countdown from 'react-countdown';

const WalletScreen = () => {
    let { id,wallet,method } = useParams();
    const navigate = useNavigate();
    const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []); 
    const toast = useToast()

    const {
        data, isLoading, isError, error,
      } = useQuery(`depositFunds${id}`, () => ApiService.getDepositFund(id,wallet,method));

      const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }


    return (
        <Flex>
        <Box w='75%'>
        {
                  isLoading? <HStack justifyContent='center'><Text>Loading...</Text></HStack> : null
           }
           {
            isError? <HStack justifyContent='center'><Text>{ error.data ?error.data.message.error.join(','): error.response ? error.response.data.message :'Something went wrong'}</Text></HStack> : null
           }
           {
            data ? (
                <Box >
                    <HStack bg={'#000'} p={2} borderRadius={4} my={8}>
                        <Link to={ROUTES.DEPOSIT_AND_WITHDRAW}>
                        <HStack>
                        <BiArrowBack />
                            <Text my={2}>Back</Text>
                        </HStack> 
                        </Link>                                              
                    </HStack>
                    <HStack px={28} my={18} alignItems='center'>
                        <Heading size='lg' >{data.method[0].name}</Heading>
                        <Spacer/>
                        <Image src={`https://staging.volleyballeliteschampions.com/assets/images/gateway/${data.method[0].image}`} width={20}/>
                    </HStack> 

                    <Box p={8} px={20}>
                      <HStack whiteSpace='nowrap' spacing={14}>
                        <Text fontSize='sm'>Wallet Address: {data.wallet.address}</Text>
                        <Button size='xs' bgColor='#F56403' px={8} ml={18} onClick={()=>{
                            navigator.clipboard.writeText(data.wallet.address);
                            toast({
                                    title: "Copied to clipboard",
                                    description:  'Wallet address copied to clipboard',
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                            });
                        }}>Copy</Button>
                      </HStack>
                        <Text fontSize='sm'>Deposit Amount: {parseFloat(data.deposit.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data.method[0].currency}</Text>
                        <HStack>
                        <Text fontSize='sm'> Time Left: </Text>
                        <Countdown date={Date.now() + (10000*60)}
                        renderer={({ minutes, seconds, completed }) => {
                            if (completed) {
                                navigate(ROUTES.DEPOSIT_AND_WITHDRAW);
                            } else {
                                // Render a countdown
                                return <span>{minutes}:{seconds}</span>;
                            }
                        }}
                         />
                        </HStack>
                    <HStack justifyContent='center' mt={4}>
                            <Image src={data.wallet.qr_code} alt={data.wallet.address} width={40}/>
                    </HStack>   
                        <Heading size='sm' pt={4} pb={2}>Instructions</Heading>
                        <Text p={4} pt={0} dangerouslySetInnerHTML={{__html: data.method[0].method.description}} fontSize='sm'/>
                    </Box>
                    
                </Box>
                ): null
           }
        </Box>
        <Box w={'23%'} pl={4}>
            <Text textAlign='center'>Bet Slips</Text>
            <VStack spacing={3} mt='2'>
                {
                    slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                }
                {
                    slips.map((slip,index) => {
                        return (
                            <BetSlip
                                match={slip.match}
                                question={slip.question}
                                option={slip.option}
                                onRemove={()=>{ removeSlip(index)}}
                            />
                        )
                    })
                }
            </VStack>
        </Box>
</Flex>
    );
};

export default WalletScreen;