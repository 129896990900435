import { Box, HStack,Icon, Text,VStack } from '@chakra-ui/react';
import React from 'react';
import { BiAddToQueue, BiHistory, BiHome, BiMoney, BiTransfer, BiWalletAlt,BiNetworkChart } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes'
import { FaUserFriends, FaTrashAlt } from "react-icons/fa";
import { RiServiceFill } from "react-icons/ri";
import { FiShoppingCart } from "react-icons/fi";


const DashboardMenu = () => {
    return (
        <VStack  minH="100vh" bg='#232527' minW='48' color='gray.200' spacing={4} pt={8} alignItems='flex-start' position={'fixed'} top='20' left='0' zIndex={10}
        >
            <NavLink to={ROUTES.DASHBOARD} style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} end={true}>
                <HStack spacing={4}>
                    <Icon as = {BiHome} />
                    <Text>Dashboard</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_PRODUCTS}>
                <HStack spacing={4}>
                    <Icon as = {FiShoppingCart} />
                    <Text>Products</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_ORDERS}>
                <HStack spacing={4}>
                    <Icon as = {BiHistory} />
                    <Text>Orders</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_TRANSACTIONS}>
                <HStack spacing={4}>
                    <Icon as = {BiTransfer} />
                    <Text>Transactions</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_WITHDRAWALS}>
                <HStack spacing={4}>
                    <Icon as = {RiServiceFill} />
                    <Text>Services</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_USERS}>
                <HStack spacing={4}>
                    <Icon as = {FaUserFriends} />
                    <Text>Users</Text>
                </HStack>
            </NavLink>
            <NavLink style={{
                width:'100%',
                padding:'0.6rem 1.8rem'
            }} to={ROUTES.DASHBOARD_TRASH}>
                <HStack spacing={4}>
                    <Icon as = {FaTrashAlt} />
                    <Text>Trash</Text>
                </HStack>
            </NavLink>
        </VStack>
    );
};

export default DashboardMenu;