import React from 'react';
import {
    Text, HStack, VStack, Spacer, Badge, useToast,
    AccordionPanel, AccordionIcon, AccordionButton, Accordion, AccordionItem, Button
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import ApiService from '../../services/api_service';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes';


import moment from 'moment';
const BetCard = ({ order }) => {
    const toast = useToast()
    const navigate = useNavigate();
    // const { mutate, isLoading } = useMutation(ApiService.updateOrderStatus({
    //     orderId: order._id,
    //     status: 'cancelled'
    
    // }), {
    //     onSuccess: () => {
    //         toast({
    //             title: "Order Cancelled",
    //             description: 'Order has been cancelled successfully',
    //             status: "success",
    //             duration: 9000,
    //             isClosable: true,
    //         });
    //         navigate(ROUTES.DASHBOARD_ORDERS);
    //     },
    //     onError: () => {
    //         toast({
    //             title: "Error Occured",
    //             description: 'Something went wrong! Please try again.',
    //             status: "error",
    //             duration: 9000,
    //             isClosable: true,
    //         });
    //     }
    // });
    return (
        <VStack borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' m={4}>
            <HStack w='100%'>
                <Text>SL No.</Text>
                <Spacer />
                <Text>{order._id}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Total Amount</Text>
                <Spacer />
                <Text>UGX {parseFloat(order.totalPrice).toLocaleString()}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Charge Amount</Text>
                <Spacer />
                <Text>UGX {'0.00'}</Text>
            </HStack>
            <HStack w='100%'>
                <Text>Status</Text>
                <Spacer />
                {
                    order.status === 'delivered' ? <Badge colorScheme='green' textTransform='capitalize' px={2} variant='solid' >Delivered</Badge> :
                        order.status === 'processing' ? <Badge colorScheme='purple' textTransform='capitalize' px={2} variant='solid' >Processing</Badge> :
                            order.status === 'cancelled' ? <Badge colorScheme='red' textTransform='capitalize' px={2} variant='solid' >Cancelled</Badge>
                                : <Badge colorScheme='yellow' textTransform='capitalize' px={2} variant='solid' >{order.status}</Badge>
                }
            </HStack>
            <HStack w='100%'>
                <Text>Time</Text>
                <Spacer />
                <Text>{moment(order.createdAt).format('MMMM Do YYYY, h:mm a')}</Text>
            </HStack>
            {
                order.status === 'pending' && (
                    <HStack justifyContent="center" w='100%'>
                        <Button w='100%' onClick={() => {
                            navigate(`${ROUTES.DASHBOARD_ORDERS}/${order._id}`, { state: { order } })
                        }}
                            // isLoading={isLoading}
                            bg="#20914D"
                            color="white">
                            Process Order
                        </Button>
                        <Button w='100%' onClick={() => {
                            // mutate();
                            navigate(`${ROUTES.DASHBOARD_ORDERS}/${order._id}`, { state: { order } })

                        }}
                            // isLoading={isLoading}
                            bg="red.400"
                            color="white">
                            Cancel Order
                        </Button>
                    </HStack>
                )
            }
            {
                order.status === 'processing' && (
                    <HStack justifyContent="center" w='100%'>
                        <Button w='100%' onClick={() => {
                            navigate(`${ROUTES.DASHBOARD_ORDERS}/${order._id}`, { state: { order } })
                        }}
                            // isLoading={isLoading}
                            bg="#232527"
                            color="white">
                            Details
                        </Button>
                        <Button w='100%' onClick={() => {
                            navigate(`${ROUTES.DASHBOARD_ORDERS}/${order._id}`, { state: { order } })
                        }}
                            // isLoading={isLoading}
                            bg="#20914D"
                            color="white">
                            Cancel Order
                        </Button>
                    </HStack>

                )
            }
            {
                order.status !== 'pending' && order.status !== 'processing' && (
                    <HStack justifyContent="center" w='100%'>
                        <Button w='100%' onClick={() => {
                            navigate(`${ROUTES.DASHBOARD_ORDERS}/${order._id}`, { state: { order } })
                        }}
                            // isLoading={isLoading}
                            bg="#232527"
                            color="white">
                            Details
                        </Button>

                    </HStack>
                )
            }
        </VStack>
    );
};

export default BetCard;