import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { firebaseApp } from "./firebase";

export const uploadImage = async (uri, entity='Products') => {
    let uploadedImageUrl = null;
  
    try {
      console.log('I am here');
      const storage = getStorage(firebaseApp);
      if (!storage) {
        console.log('Storage reference is empty');
        return;
      }
  
      console.log('I am here with uri', uri);
  
      if (!uri) return;
  
      console.log('Uri is not empty', uri);
  
      const response = await fetch(uri);
      const blob = await response.blob();
  
      if (!blob) {
        console.log("Unable to analysis video data. Please try again");
        return;
      }
  
      const imageUrl = `/Images/${entity}/${new Date().toISOString()}`;
  
      console.log('Blob is fetched', imageUrl);
  
      const imageRef = ref(storage, imageUrl);
  
      if (!imageRef) {
        console.log("Unable to upload the video properly. Please try again.");
        return;
      }
  
      const snapshot = await uploadBytesResumable(imageRef, blob);
      if (snapshot.ref) {
        console.log('Get download url');
        await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
          console.log('download url', downloadURL);
          if (downloadURL) {
            console.log("Image uploaded successfully:", downloadURL);
            console.log("File available at", downloadURL);
  
            uploadedImageUrl = downloadURL;
            return uploadedImageUrl;
          }
        });
      } else {
        console.log('snapshot ref is not available');
      }
  
    } catch (error) {
      console.error("Upload failed: ", error);
    }
  
    return uploadedImageUrl;
  };


export const uploadGuide = async (uri) => {
    let uploadedImageUrl = null;
  
    try {
      console.log('I am here');
      const storage = getStorage(firebaseApp);
      if (!storage) {
        console.log('Storage reference is empty');
        return;
      }
  
      console.log('I am here with uri', uri);
  
      if (!uri) return;
  
      console.log('Uri is not empty', uri);
  
      const response = await fetch(uri);
      const blob = await response.blob();
  
      if (!blob) {
        console.log("Unable to analysis video data. Please try again");
        return;
      }
  
      const imageUrl = `/guides/${new Date().toISOString()}`;
  
      console.log('Blob is fetched', imageUrl);
  
      const imageRef = ref(storage, imageUrl);
  
      if (!imageRef) {
        console.log("Unable to upload the video properly. Please try again.");
        return;
      }
  
      const snapshot = await uploadBytesResumable(imageRef, blob);
      if (snapshot.ref) {
        console.log('Get download url');
        await getDownloadURL(snapshot.ref).then(async (downloadURL) => {
          console.log('download url', downloadURL);
          if (downloadURL) {
            console.log("Image uploaded successfully:", downloadURL);
            console.log("File available at", downloadURL);
  
            uploadedImageUrl = downloadURL;
  
            return uploadedImageUrl;
          }
        });
      } else {
        console.log('snapshot ref is not available');
      }
  
    } catch (error) {
      console.error("Upload failed: ", error);
    }
  
    return uploadedImageUrl;
  };