import React from 'react';
import {
  ChakraProvider,
  extendTheme,
} from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './router';
import { QueryClientProvider } from 'react-query';
import UseQueryHelper from './utils/constants/helpers/UseQuery';
import './app.css'

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: true,
})


function App() {
  return (
    <ChakraProvider theme={theme}>
    <QueryClientProvider client={UseQueryHelper.queryClient}>
          <Router>
              <AppRouter />
            </Router>
    </QueryClientProvider>
    </ChakraProvider>

  );
}

export default App;
