import {
    Text, Box, HStack, Stat, StatLabel, StatNumber,
    StatHelpText, Card, useToast, Spinner, VStack, Spacer,Button, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Link, Image
} from '@chakra-ui/react';
import React, { useState } from 'react';
import ApiService from '../../services/api_service';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes';
import BetCard from '../../components/dashboard/bet_card';
import moment from 'moment';

const Dashboard = () => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const {
        data, isLoading, isError, error,
    } = useQuery('dashboard', () => {
        if (!userCredentails._id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
        return ApiService.dashboard()
    }, {
        onSuccess: (dataReturned) => {
            toast({
                title: "Success",
                description: 'User Updates Fetched  Successfully',
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }
    });
    return (
        <Box p={6} w='100%' minH={'100vh'}>
            {isLoading && <Spinner />}
            {isError && <HStack justifyContent='center'><Text>{error.data.errors ? error.data.errors[0].message : "Something happened"}</Text></HStack>}
            {data && (
                <>
                    <Text>Dashboard</Text>
                    <HStack py={4} flexWrap='wrap' gap='4' >
                        <Card px={8} py={4} bgColor='gray.700' color='gray.100' m='4'>
                            <Stat>
                                <StatLabel>Users</StatLabel>
                                <StatNumber>{data.totalUsers}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>

                        <Card px={8} py={4} bgColor='gray.700' color='gray.100'>
                            <Stat>
                                <StatLabel>Products</StatLabel>
                                <StatNumber> {data.totalProducts}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>
                        <Card px={8} py={4} bgColor='gray.700' color='gray.100'  >
                            <Stat>
                                <StatLabel>Services </StatLabel>
                                <StatNumber>{data.totalServices}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>
                        <Card px={8} py={4} bgColor='gray.700' color='gray.100' >
                            <Stat>
                                <StatLabel>Orders</StatLabel>
                                <StatNumber> {data.totalOrders}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>
                        <Card px={8} py={4} bgColor='gray.700' color='gray.100'>
                            <Stat>
                                <StatLabel>Total Transactions</StatLabel>
                                <StatNumber> {data.totalTransactions}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>
                        <Card px={8} py={4} bgColor='gray.700' color='gray.100'>
                            <Stat>
                                <StatLabel>Total Revenue</StatLabel>
                                <StatNumber> {data.totalRevenue}</StatNumber>
                                <StatHelpText>{moment(userCredentails.createdAt).format('MMM YYYY')} - {moment().format('MMMM YYYY')}</StatHelpText>
                            </Stat>
                        </Card>
                    </HStack>
                    <HStack py={4} gap={20}>
                        <VStack w='50%'>
                            <HStack w='100%'><Text>Latest Products</Text>
                                <Spacer />
                                <Button colorScheme="blue" variant='link' onClick={()=>{navigate(ROUTES.DASHBOARD_PRODUCTS)}}>View All</Button>
                            </HStack>
                            <TableContainer>
                                <Table size='md'>
                                    <Thead>
                                        <Tr>
                                            <Th></Th>
                                            <Th>Name</Th>
                                            <Th>Category</Th>
                                            <Th>Price</Th>
                                            <Th isNumeric>Quantity</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.latestProducts.map((product, index) => (
                                            <Tr key={index}>
                                                <Td><Image src={product.img} h={10} /></Td>
                                                <Td>{product.title}</Td>
                                                <Td>{product.category}</Td>
                                                <Td>UGX {parseFloat(product.price).toLocaleString()}</Td>
                                                <Td isNumeric>{product.avaliableQuantity}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </VStack>
                        <VStack w='40%'>
                            <HStack w='100%'><Text>Latest Services</Text>
                                <Spacer />
                                <Button colorScheme="blue" variant='link' onClick={()=>{navigate(ROUTES.DASHBOARD_WITHDRAWALS)}}>View All</Button>
                            </HStack>
                            <TableContainer>
                                <Table size='md' >
                                    <Thead>
                                        <Tr>
                                            <Th></Th>
                                            <Th>Name</Th>
                                            <Th>Category</Th>
                                            <Th >Guide</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.latestServices.map((service, index) => (
                                            <Tr key={index}>
                                                <Td><Image src={service.img} h={10} /></Td>
                                                <Td>{service.title}</Td>
                                                <Td>{service.category}</Td>
                                                <Td><Link href={service.guide} color='blue.400'>Read guide</Link></Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </VStack>
                    </HStack>
                    <VStack alignItems='flex-start' py="4">

                    </VStack>
                </>
            )}
        </Box>
    );
};

export default Dashboard;