import { Box,Divider,Flex,HStack,Image,Text,VStack } from '@chakra-ui/react';
import React,{useState} from 'react';
import BetSlip from '../../components/home/bet_slip';
import ApiService from '../../services/api_service';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

const Deposit = () => {
    const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []); 
    const {
        data, isLoading, isError, error,
      } = useQuery('depositMethods', ApiService.getDepositMethods);
    const {
    data: data2, isLoading:isLoading2, isError:isError2, error:error2,
    } = useQuery('withdrawalMethods', ApiService.getWithdrawalMethods);


    const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }

    return (
        <Box>
            <Flex>
                <Box w='75%'>
                    <Box p={4}>
                        <HStack bg={'#000'} p={2} borderRadius={4}>
                            <Text my={2}>Deposit Methods</Text>                            
                        </HStack>
                        <HStack p={2}>
                            {
                                isLoading ? <Text>Loading...</Text> : null
                            }
                            {
                                isError ? <Text>{error}</Text> : null
                            }
                            {
                            data ? data.map((method) => {
                                return (
                                    <Link to={`/app/deposit/${method.id}`} style={{width:'50%'}}>
                                        <Box w={'100%'} border='1px solid #f4f4f4' borderRadius={4} p={2}>
                                            <Text fontSize='sm' py={2} align='center'>Deposit with {method.name}</Text>
                                            <Divider/>
                                            {/* <Text fontSize='sm' py={2} align='left'>{method.method.description}</Text> */}
                                        <HStack justifyContent={'center'}>
                                            <Image  src={`https://staging.volleyballeliteschampions.com/assets/images/gateway/${method.image}`} alt={method.name}  h={100} align='center'/>
                                        </HStack>
                                        </Box>
                                    </Link>
                                )
                            }) :null
                            }
                        </HStack>
                        <HStack bg={'#000'} p={2} borderRadius={4} mt={8}>
                            <Text my={2}>Withdrawal Methods</Text>                            
                        </HStack>
                        <HStack p={2}>
                            {
                                isLoading2 ? <Text>Loading...</Text> : null
                            }
                            {
                                isError2 ? <Text>{error2.message}</Text> : null
                            }
                            {
                                data2 ? data2.map((method) => {
                                return (
                                    <Link to={`/app/withdraw/${method.id}`} style={{width:'50%'}}>
                                    <Box w={'100%'} border='1px solid #f4f4f4' borderRadius={4} p={2}>
                                        <Text fontSize='sm' py={2} align='center'>Withdraw with {method.name}</Text>
                                        <Divider/>
                                        {/* <Text fontSize='sm' py={2} align='left'>{method.method.description}</Text> */}
                                       <HStack justifyContent={'center'}>
                                        <Image  src={`https://staging.volleyballeliteschampions.com/assets/images/withdraw/method/${method.image}`} alt={method.name}  h={100} align='center'/>
                                       </HStack>
                                    </Box>
                                    </Link>
                                )
                            }): null
                            }
                        </HStack>
                    </Box>
                </Box>
                <Box w={'23%'} pl={4}>
                <Text textAlign='center'>Bet Slips</Text>
                    <VStack spacing={3} mt='2'>
                        {
                            slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                        }
                        {
                            slips.map((slip,index) => {
                                return (
                                    <BetSlip
                                        match={slip.match}
                                        question={slip.question}
                                        option={slip.option}
                                        onRemove={()=>{ removeSlip(index)}}
                                    />
                                )
                            })
                        }
                    </VStack>
                </Box>
                </Flex>

        </Box>
    );
};

export default Deposit;