import React from 'react';
import { Text, HStack, VStack, Spacer, } from '@chakra-ui/react';
import moment from 'moment';
const CommissionCard = ({commission}) => {
    return (
        <VStack w='100%' borderRadius={4} px={4} py="2" alignItems='flex-start' spacing={2} bgColor='gray.700' color='gray.100' mt={4}>
        <HStack w='100%'>
            <Text>Commision Reference.</Text>
            <Spacer />
            <Text>{commission.trx}</Text>
        </HStack>
        <HStack w='100%'>
            <Text>Amount</Text>
            <Spacer />
            <Text>USDT {parseFloat(commission.amount).toFixed(2)}</Text>
        </HStack>
        <HStack w='100%'>
            <Text>Charge Amount</Text>
            <Spacer />
            <Text>USDT {'0.00'}</Text>
        </HStack>
        <HStack w='100%'>
            <Text>Details</Text>
            <Spacer />
            <Text>{commission.details}</Text>
        </HStack>
        <HStack w='100%'>
            <Text>Time</Text>
            <Spacer />
            <Text>{moment(commission.created_at).format('MMMM Do YYYY, h:mm:ss a')}</Text>
        </HStack>
    </VStack>
    );
};

export default CommissionCard;