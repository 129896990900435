import axios from 'axios';

// Create an instance of Axios with a base URL
const instance = axios.create({
    baseURL: 'https://omia-backend.onrender.com',
});

instance.defaults.withCredentials = true;

// Add request interceptor
instance.interceptors.request.use(
    (config) => {
        // Modify the request config here (e.g., add headers, authentication tokens)
        config.withCredentials = true;
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.token) {
            config.headers = { Accept: 'application/json', Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))?.token || null}` }
        }
        return config;
    },
    (error) => {
        // Handle request error here
        return Promise.reject(error);
    }
);
// Add response interceptor
instance.interceptors.response.use(
    (response) => {
        // Modify the response data here (e.g., transform, filter)
        return response;
    },
    (error) => {
        // Handle response error here
        if (error.response) {
            // Snackbar.show({
            //   text: error.response.data,
            //   duration: Snackbar.LENGTH_LONG,
            //   action: {
            //     text: "close",
            //     textColor: "white",
            //   },
            //   backgroundColor: "red",
            // });
            return error.response;
          }
          return error
        // return Promise.reject(error);
    }
);

export default instance;