export const LANDING = '/';

// Auth routes
export const LOGIN = '/auth/login';
export const REGISTER = '/auth/register/:referral?';
export const CONTACT_US = '/contact-us';
export const FORGOT_PASSWORD = '/auth/forgot-password';
export const RESET_PASSWORD = '/auth/reset-password';

// Dashboard routes
export const BET_SLIPS = '/app/bet-slips'
export const LEAGUE_GAMES = '/leagues/:id';
export const DEPOSIT_AND_WITHDRAW = '/depositwithdraws';
export const DEPOSIT_FUNDS_WAITING = '/app/deposit/:method/:wallet/:id';
export const DASHBOARD = '/app/dashboard';
export const DASHBOARD_ORDERS = '/app/dashboard/orders';
export const DASHBOARD_TRANSACTIONS = '/app/dashboard/transactions';
export const DASHBOARD_PRODUCTS = '/app/dashboard/products';
export const DASHBOARD_ADD_PRODUCTS = '/app/dashboard/products/add_product';
export const DASHBOARD_EDIT_PRODUCTS = '/app/dashboard/products/edit_product';

export const DASHBOARD_WITHDRAWALS = '/app/dashboard/services';
export const DASHBOARD_ADD_SERVICE = '/app/dashboard/services/add_service';
export const DASHBOARD_EDIT_SERVICE = '/app/dashboard/services/edit_service';

export const DASHBOARD_USERS = '/app/dashboard/users';

export const DASHBOARD_PROFILE = '/app/dashboard/profile';
export const DASHBOARD_REFERRAL = '/app/dashboard/referral';
export const DASHBOARD_COMMISSION = '/app/dashboard/commission';
export const DASHBOARD_TRASH = '/app/dashboard/trash';

export const DASHBOARD_ORDER_DETAILS = '/app/dashboard/orders/:id'


export const PROFILE = '/app/profile';

