import { Card, CloseButton, Text ,Flex, HStack, Button, Input, ButtonGroup, Spacer, useToast, Hide} from '@chakra-ui/react';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../services/api_service';
import * as ROUTES from '../../utils/constants/routes';
const BetSlip = ({match,option,question,onRemove}) => {
    let [amount,setAmount] = React.useState(0.00);
    const toast = useToast();
    const navigate = useNavigate
    const { mutate, isLoading,} = useMutation(
      (details) => ApiService.betStore(details),{
          onSuccess: (data) => {
            if(data.code===200){
              toast({
                title: "Success",
                description:  'Investment  Submitted Successfully',
                status: "success",
                duration: 4000,
                isClosable: true,
              });
              onRemove();
            }
            else{
              toast({
                title: "Error Occured",
                description:  data.message.error.join("\n"),
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            }
         
          },
          onError: (error) => {
              toast({
                  title: "Error Occured",
                  description:  error.data ?error.data.message.error.join(','): error.response ? error.response.data.message :'Something went wrong',
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
          }
      })
    return (
        <Card w={'100%'} p="4" bgColor='#5F3822' color='gray.100'>
            <Flex justifyContent='flex-end' mb={4}>
                <CloseButton onClick={onRemove}/>
            </Flex>
            <Text fontSize='sm' textAlign='center'>{match.name}</Text>
            <Text fontSize='sm' textAlign='center' my='2'>{question.name}:{' '+ option.name}</Text>
            <Flex>
                <Text fontSize='sm'>Total Odds</Text>
                <Spacer/>
                <Text fontSize='sm'>{((option.divisor-1)*100).toFixed(2)}</Text>
            </Flex>
            <Flex>
                <Text fontSize='sm'>Possible Win</Text>
                <Spacer/>
                <Text fontSize='sm'> {parseFloat(amount * option.divisor).toFixed(2)}  USDT</Text>
            </Flex>

            <HStack my={4}>
                <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#F56403'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  borderRadius={'2'}
                  px={4} 
                  onClick={()=>setAmount(amount-1)}
                  >-</Button>
                  <Input placeholder='0.00' type='number' size='sm' borderRadius='2' value={amount} onChange={(e)=>{
                        setAmount(e.target.value);
                  }}/>
                  <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#F56403'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4}  borderRadius={'2'} onClick={()=>setAmount(amount+1)}>+</Button>
            </HStack>

           <Hide below='md'>
           <ButtonGroup flexWrap='wrap' >
            <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4} onClick={()=>setAmount(50.0)} mb='2'>50.0</Button>
                  <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4} onClick={()=>setAmount(100.0)} mb='2'>100.0</Button>
                  <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4} onClick={()=>setAmount(200.0)} mb='2'>200.0</Button>
                  <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4} onClick={()=>setAmount(500.0)} mb='2'>500.0</Button>
                  <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#3B3C3F'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer'
                  }}
                  size='sm'
                  px={4}>All</Button>
            </ButtonGroup>
           </Hide>

            <Button fontSize={'sm'}
                  fontWeight={600}
                  color={'white'}
                  bg={'#F56403'}
                  _hover={{
                    opacity: 0.5,
                    cursor: 'pointer',
                  }}
                  size='sm'
                  px={4} mt='23'
                  onClick={()=>{
                    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
                    if( !user ){
                      toast({
                          title: "Authentication Required",
                          description: 'Login in to continue with this action ',
                          status: "warning",
                          duration: 9000,
                          isClosable: true,
                          });
                      navigate(ROUTES.LOGIN);
                  }
                    if(amount > 0){
                        mutate({
                            match_id: match.id,
                            question_id: question.id,
                            option_id: option.id,
                            invest_amount: amount,
                            user_id: user.id,
                        })
                    } else {
                        toast({
                            title: "Error Occured",
                            description:  'Please enter a valid amount',
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                    }

                  }}
                  isLoading={isLoading}>Place Bet</Button>

        </Card>
    );
};

export default BetSlip;