import { parsePhoneNumber } from 'react-phone-number-input'
import lookup from 'country-code-lookup'

import Axios from './Axios';
class ApiService {
    static  getLeagues() {
      return  Axios.get('/api/games/leagues').then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data.leagues);
        }
        return Promise.reject(res);
      });;
    }
    static  getHomeGames() {
        return  Axios.get('/api/games/home').then((res) => {
          if (res.status === 200) {
            return Promise.resolve(res.data.data.matches);
          }
          return Promise.reject(res);
        });;
      }
    static getLeagueGames(id) {
        return Axios.get(`/api/leagues/${id}`).then((res) => {
            if (res.status === 200) {
                return Promise.resolve(res.data.data);
              }
              return Promise.reject(res);
        })
    }
    static getDepositMethods() {
      return Axios.get('/api/deposit/methods').then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data.methods);
        }
        return Promise.reject(res);
      });
    }
    static getWithdrawalMethods() {
      return Axios.get('/api/withdrawal/methods').then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data.methods);
        }
        return Promise.reject(res);
      });
    }
    static getWithdrawalMethod(id) {
      return Axios.get(`/api/withdrawal/${id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data.methods);
        }
        return Promise.reject(res);
      });
    }
    static getDepositMethod(id) {
      return Axios.get(`/api/deposit/${id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data.methods);
        }
        return Promise.reject(res);
      });
    }

    static register(details){
      const phoneNumber = parsePhoneNumber(details.mobile)
      details.country_code = phoneNumber.country;
      details.mobile_code = phoneNumber.countryCallingCode;
      details.password_confirmation = details.password;
      details.agree = true;
      const loc = lookup.byIso(phoneNumber.country);
      details.country = loc.country;
      return Axios.post('/api/register',details).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }

    static login(details){
      return Axios.post('/api/users/login',details).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }

    static logout(){
      return Axios.post('/api/users/signout').then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static addFunds(details){
      return Axios.post('/api/deposit/register',details).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }

    static getDepositFund(id,wallet,method){
      return Axios.get(`/api/depositFunds/${id}/${wallet}/${method}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }

    static withdrawStore(details){
      return Axios.post('/api/withdrawal/store',details).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static betStore(details){
      return Axios.post('/api/bet/store',details).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }

    static dashboard (){
      return Axios.get(`/api/admin/stats`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        console.log("Response status: " + res.data)
        return Promise.reject(res);
      });
    }
    static orderHistory (){
      return Axios.get(`/api/admin/orders`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static transactions (){
      return Axios.get(`/api/admin/payments`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static products(){
      return Axios.get(`/api/admin/products`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static addProducts(product){
      return Axios.post(`/api/admin/products/create`,product).then((res) => {
        if (res.status === 201) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static updateProducts(product){
      return Axios.put(`/api/products/update/${product._id}`,product).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static deleteProduct(product){
      return Axios.delete(`/api/product/${product._id}`).then((res) => {
        if (res.status === 204) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static services (){
      return Axios.get(`/api/admin/services`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static addService(service){
      return Axios.post(`/api/admin/services/create`,service).then((res) => {
        if (res.status === 201) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static updateService(service){
      return Axios.put(`/api/admin/services/update/${service._id}`,service).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static deleteService(service){
      return Axios.delete(`/api/admin/services/delete/${service._id}`).then((res) => {
        if (res.status === 204) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static restoreProduct(product){
      return Axios.put(`/api/product/restore/${product._id}`).then((res) => {
        if (res.status === 204) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static restoreService(service){
      return Axios.put(`/api/admin/services/restore/${service._id}`).then((res) => {
        if (res.status === 204) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static users(){
      return Axios.get(`/api/admin/users`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static trash(){
      return Axios.get(`/api/admin/trash`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static deposits (user){
      return Axios.get(`/api/dashboard/deposits/${user.id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }
    static getUser(){
      return Axios.get(`/api/users/currentuser`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static referrals (user){
      return Axios.get(`/api/dashboard/referrals/${user.id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }
    static levelReferrals (user,level){
      return Axios.get(`/api/dashboard/referral/${user.id}/${level}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }
    static getCommission(user,type){
      return Axios.get(`/api/dashboard/commsion/${type}/${user.id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }

    static cancelBet(user,bet_id){
      return Axios.get(`/api/dashboard/bets/${user.id}/${bet_id}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data.data);
        }
        return Promise.reject(res);
      });
    }
    static getOrderDetiails(orderId){
      return Axios.get(`/api/admin/orders/${orderId}`).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
    static updateOrderStatus(payload){
      return Axios.put(`/api/admin/orders/status`,{...payload}).then((res) => {
        if (res.status === 200) {
          return Promise.resolve(res.data);
        }
        return Promise.reject(res);
      });
    }
}
export default ApiService;