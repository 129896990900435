import { Box, Flex,Hide,useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import Header from '../Main/components/header';
import Footer from '../Main/components/footer';
import DashboardMenu from './menu';

const DashboardLayout = ({children}) => {
    return (
        <Box bg='#2B2E31'>
            <Header />
            <main bg='#2B2E31' >
               <Flex pt={32} w='100%'>
                    <Hide below='md'>
                        <DashboardMenu/>
                    </Hide>
                    <Box w={['100','160','240','360', ]} pt='2' bg='#2B2E31' color='gray.100' pb={12} ml={'48'}>
                        {children}
                    </Box>
               </Flex>
            </main>
            {/* <Footer /> */}
        </Box>
    );
};

export default DashboardLayout;