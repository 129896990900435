import { Box,HStack,Text,Image, Spacer, Flex, VStack,Hide, Spinner } from '@chakra-ui/react';
import React, {useState} from 'react';
import { useQuery } from 'react-query';
import MatchCard from '../../components/home/match_card';
import ApiService from '../../services/api_service';
import { useParams } from 'react-router-dom';
import BetSlip from '../../components/home/bet_slip';

const LeagueGames = () => {
    let { id } = useParams();
    const {
        data, isLoading, isError, error,
      } = useQuery(`leagueGames${id}`, () => ApiService.getLeagueGames(id));
      const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []); 

      const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }
    return (
        <Flex>
            <Box p={4} w={['100%','75%','75%','75%' ]} pb='24'>
                <HStack justifyContent='flex-end' spacing={2} py={3}>
                        <Text my={8} >{data?.league.name} Games</Text>
                        <Spacer/>
                        <Image src={data?.league.icon} alt={data?.league.name} boxSize={24}/>
                        {/*  */}
                </HStack>
            {
                isLoading? <HStack justifyContent='center'><Spinner/><Text>Loading...</Text></HStack> : null
            }
            {
                isError? <HStack justifyContent='center'><Text>{error}</Text></HStack> : null
            }
            {
                    data?.matches.length === 0 ? <HStack justifyContent='center'><Text>No Games Found!!</Text></HStack> : null
            }
            {
                    data?.matches.map((match) => {
                        return (
                            <MatchCard match={match} setSlips={setSlips} key={match.id}/>
                        )
                    })
            }
            </Box>
            <Hide below='md'>
            <Box w={ ['0%','23%','23%','23%' ]} pl={4}>
                    <Text textAlign='center'>Bet Slips</Text>
                    <VStack spacing={3} mt='2'>
                        {
                            slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                        }
                        {
                            slips.map((slip,index) => {
                                return (
                                    <BetSlip
                                        match={slip.match}
                                        question={slip.question}
                                        option={slip.option}
                                        onRemove={()=>{ removeSlip(index)}}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </VStack>
                </Box>
                </Hide>
        </Flex>
        
    );
};

export default LeagueGames;