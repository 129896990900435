import React from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, HStack, Text, Flex } from '@chakra-ui/react'
import CommisionContent from '../../components/dashboard/commission_tab';
import { useQuery } from 'react-query';
import ApiService from '../../services/api_service';
import DepositCard from '../../components/dashboard/deposit_card';
import WithdrawalCard from '../../components/dashboard/withdrawal_card';
import UserCard from '../../components/dashboard/user_card';
const Commision = () => {
    const {
        data, isLoading, isError, error,
    } = useQuery('products', () => ApiService.trash()
        , {
            onSuccess: () => {
                // toast({
                //     title: "Success",
                //     description: 'User Deposits Fetched  Successfully',
                //     status: "success",
                //     duration: 9000,
                //     isClosable: true,
                // });
            }
        });

    return (
        <Box p={6} minH='100vh' w='100%'>
            {isLoading && <Spinner />}
            {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
            {data && (<Tabs >
                <TabList>
                    <Tab _selected={{ color: '#F56403', bg: 'transparent', borderColor: '#F56403' }} _hover={{ bgColor: 'transparent' }}>Products</Tab>
                    <Tab _selected={{ color: '#F56403', bg: 'transparent', borderColor: '#F56403' }} _hover={{ bgColor: 'transparent' }}>Services</Tab>
                    <Tab _selected={{ color: '#F56403', bg: 'transparent', borderColor: '#F56403' }} _hover={{ bgColor: 'transparent' }}>Users</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Flex alignItems='flex-start' py="4" w="100vw" flexWrap='wrap'>
                            {[...data.products].reverse().map((deposit) => (
                                <DepositCard deposit={deposit} key={deposit._id} forTrash={true} />
                            ))}
                            {data.products.length <= 0 && <Text>No Products Found</Text>}
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex alignItems='flex-start' py="4" w="100vw" flexWrap='wrap'>
                            {[...data.services].reverse().map((service) => (
                                <WithdrawalCard service={service} key={service._id} forTrash={true} />
                            ))}
                            {data.services.length <= 0 && <Text>No Services Found</Text>}

                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Flex alignItems='flex-start' py="4" w="100vw" flexWrap='wrap'>
                            {[...data.users].reverse().map((user) => (
                                <UserCard user={user} key={user._id} />
                            ))}
                            {data.users.length <= 0 && <Text>No Users Found</Text>}
                        </Flex>
                    </TabPanel>
                </TabPanels>
            </Tabs>)}
        </Box>
    );
};

export default Commision;