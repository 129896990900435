import { Flex, Box,Text,VStack,HStack, Heading, Spacer,Image,FormControl,FormLabel,Input,FormHelperText, Button,useToast  } from '@chakra-ui/react';
import React, {useState} from 'react';
import BetSlip from '../../components/home/bet_slip';
import { useMutation, useQuery } from 'react-query';
import ApiService from '../../services/api_service';
import { BiArrowBack } from 'react-icons/bi';
import * as ROUTES from '../../utils/constants/routes';
import { Link, useParams,useNavigate } from 'react-router-dom';


const DepositForm = () => {
    const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []); 
    let { id } = useParams();
    const {
        data, isLoading, isError, error,
      } = useQuery(`deposit${id}`, () => ApiService.getDepositMethod(id));
    const { mutate,  isLoading: isDepositLoading, error: despoitError} = useMutation(
        (details) => ApiService.addFunds(details),{
            onSuccess: (reponseData) => {
                    toast({
                        title: "Success",
                        description:  'Deposit Request Submitted Successfully',
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      console.log('Deposit:',reponseData.deposit)
                    navigate(`/app/deposit/${data[0].id}/${reponseData.deposit.wallet_address}/${reponseData.deposit.id}`);
               
            },
            onError: (error) => {
                toast({
                    title: "Error Occured",
                    description:  despoitError.data ?despoitError.data.message.error.join(','): despoitError.response ? despoitError.response.data.message :'Something went wrong',
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
            }
        })
    const navigate = useNavigate();
    const toast = useToast()
    const [amount,setAmount] = useState(0);


    const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }


    return (
        <Flex>
                <Box w='75%' pb='28'>
                {
                          isLoading? <HStack justifyContent='center'><Text>Loading...</Text></HStack> : null
                   }
                   {
                    isError? <HStack justifyContent='center'><Text>{error}</Text></HStack> : null
                   }
                   {
                    data ? (
                        <Box >
                            <HStack bg={'#000'} p={2} borderRadius={4} my={8}>
                                <Link to={ROUTES.DEPOSIT_AND_WITHDRAW}>
                                <HStack>
                                <BiArrowBack />
                                    <Text my={2}>Back</Text>
                                </HStack> 
                                </Link>                           
                            </HStack>
                                <HStack px={28} my={18} alignItems='center'>
                                    <Heading size='lg' >{data[0].name}</Heading>
                                    <Spacer/>
                                    <Image src={`https://staging.volleyballeliteschampions.com/assets/images/gateway/${data[0].image}`} width={20}/>
                                </HStack>                               
                                <Box p={8} px={20}>
                                    <Text>Minimum Deposit Amount: {parseFloat(data[0].min_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data[0].currency}</Text>
                                    <Text>Maximum Deposit Amount: {parseFloat(data[0].max_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {data[0].currency}</Text>
                                    <Heading size='sm' pt={4} pb={2}>Instructions</Heading>
                                    <Text p={4} pt={0} dangerouslySetInnerHTML={{__html: data[0].method.description}}/>
                                </Box>
                            
                                <Heading px={20} my={4} size='md'>Deposit Form</Heading>
                                <Box px={24} pl={20}>
                                <FormControl isRequired>
                                    <FormLabel>Amount</FormLabel>
                                    <Input type='number'  name='amount'  onChange={ (e)=>{setAmount(e.target.value)}}/>
                                    <FormHelperText>Amount you want to Deposit.</FormHelperText>
                                </FormControl>
                                <Button  bg='#F56403' my={4} width='100%'
                                _hover={
                                    {
                                        bg:'#5F3822'
                                    }
                                }
                                onClick={()=>{
                                    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
                                    if(amount <= 0){
                                        toast({
                                            title: "Error Occured",
                                            description:  'Amount is required',
                                            status: "error",
                                            duration: 9000,
                                            isClosable: true,
                                          });
                                    }
                                    if( !user ){
                                        toast({
                                            title: "Authentication Required",
                                            description: 'Login in to continue with this action ',
                                            status: "warning",
                                            duration: 9000,
                                            isClosable: true,
                                            });
                                        navigate(ROUTES.LOGIN);
                                    }
                                    else{
                                        mutate({amount: amount,method_code:data[0].method.code,currency:data[0].currency,user_id: user.id});
                                    }
                                }}
                                isLoading={isDepositLoading}
                                >Deposit Amount</Button>
                                </Box>

                        </Box>
                        ): null
                   }
                </Box>
                <Box w={'23%'} pl={4}>
                    <Text textAlign='center'>Bet Slips</Text>
                    <VStack spacing={3} mt='2'>
                        {
                            slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                        }
                        {
                            slips.map((slip,index) => {
                                return (
                                    <BetSlip
                                        match={slip.match}
                                        question={slip.question}
                                        option={slip.option}
                                        onRemove={()=>{ removeSlip(index)}}
                                    />
                                )
                            })
                        }
                    </VStack>
                </Box>
       </Flex>
    );
};

export default DepositForm;