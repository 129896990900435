// @flow 
import React,{useState} from 'react';
import { Box,Text,VStack } from '@chakra-ui/react';
import BetSlip from '../../components/home/bet_slip';

export const BetSlipsMobile = () => {
    const [slips,setSlips] =  useState(localStorage.getItem('slip') ? JSON.parse(localStorage.getItem('slip')): []); 
    const removeSlip = (index) => {
        let slip = [...slips];
        slip.splice(index,1);
        localStorage.setItem('slip',JSON.stringify(slip));
        setSlips(JSON.parse(localStorage.getItem('slip')));
      }
    return (
        <Box p={4} minH='100vh' >
            <Text mt="4">Bet Slips</Text>
            <VStack spacing={3} mt='8'>
                            {
                                slips.length === 0 ? <Text fontSize='sm'>No Bet Slip Found!!</Text> : null
                            }
                            {
                                slips.map((slip,index) => {
                                    return (
                                        <BetSlip
                                            match={slip.match}
                                            question={slip.question}
                                            option={slip.option}
                                            onRemove={()=>{ removeSlip(index)}}
                                        />
                                    )
                                })
                            }
                        </VStack>
        </Box>
    );
};