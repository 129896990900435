import React,{useState} from 'react';
import * as ROUTES from '../../utils/constants/routes';
import { useNavigate } from 'react-router-dom';
import { useToast,Box,Spinner,HStack,Text,VStack } from '@chakra-ui/react';
import ApiService from '../../services/api_service';
import { useQuery } from 'react-query';
import RefferalCard from './referral_card';
const ReferalLevelList = ({level}) => {
    const toast = useToast();
    const [userCredentails, setUserCredentials] = useState(localStorage.getItem('user') ? { ...JSON.parse(localStorage.getItem('user')) } : {});
    const navigate = useNavigate();
    const {
        data, isLoading, isError, error,
    } = useQuery(`referrals-${level}`, () => {
        if (!userCredentails.id) {
            toast({
                title: "Authentication Required",
                description: 'Login in to continue with this action ',
                status: "warning",
                duration: 9000,
                isClosable: true,
            });
            navigate(ROUTES.LOGIN);
        }
      return  ApiService.levelReferrals(userCredentails,level)
    }, {
        onSuccess: () => {
            toast({
                title: "Success",
                description: 'User Referrals Fetched  Successfully',
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        }
    });
    return (
        <Box p={6} minH='100vh' w='100%'>
        {isLoading && <Spinner />}
       {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
       {data && (
           <VStack alignItems='flex-start' py="4" w='100%'>
           <Text mt="4">Level {level} Referrals : {data.referrals.length}</Text>
               {data.referrals.map((referral) => (
                   <RefferalCard referral={referral} key={referral.id}  level={level}/>
               ))}
           </VStack>
       )}
       </Box>
    );
};

export default ReferalLevelList;