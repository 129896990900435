import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCRLdr_Yku4h567M4-4M1Ktdvx-d80Or7Y",
    authDomain: "omia-app.firebaseapp.com",
    projectId: "omia-app",
    storageBucket: "omia-app.appspot.com",
    messagingSenderId: "161435945048",
    appId: "1:161435945048:web:19fd01a4c716a91daca22d",
    measurementId: "G-EZ90F0J3HD"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
