import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, Select, Flex, Image, useToast, HStack, Text, VStack } from '@chakra-ui/react';
import {  SERVICE_CATEGORIES } from '../../utils/constants';
import { useMutation } from 'react-query';
import ApiService from '../../services/api_service';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from '../../utils/constants/routes'
import { uploadGuide, uploadImage } from '../../services/FileUploadService';
import { MdKeyboardBackspace } from "react-icons/md";

const EditServiceForm = () => {
    const {state} = useLocation()
    const {service} = state
    const [name, setName] = useState(service.title);
    const [description, setDescription] = useState(service.description);
    const [image, setImage] = useState(null);
    const [guide, setGuide] = useState(null);
    const [category, setCategory] = useState(service.category);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(service.img);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();
    const { mutate, isError, isLoading, isSuccess, error, data } = useMutation(
        ({imageURl,guideUrl}) => ApiService.updateService({
            title: name,
            description,
            category: category,
            img:imageURl,
            _id:service._id,
            guide:guideUrl
        }),
        {
            onSuccess: () => {
                toast(
                    {
                        title: "Service Updated",
                        description: "Service has been updated successfully",
                        status: "success",
                        duration: 5000,
                    }
                )
                navigate(ROUTES.DASHBOARD_WITHDRAWALS);
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
            }

        }
    )

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !description || !category ) {
            console.log('All fields are required!');
            return;
        }
        setLoading(true)

        let imageURl = service.image;
        let guideUrl = service.guide;
        if (guide) {
            guideUrl = await uploadGuide(URL.createObjectURL(guide));
        }
        if (image) {
            // Upload Image to Firebase
            imageURl = await uploadImage(imagePreviewUrl,"Services");
        }
        mutate({imageURl,guideUrl});
        setLoading(false)
        // Continue with form submission logic
    };

    const resetForm = () => {
        setName(service.title);
        setDescription(service.description);
        setImage(null);
        setCategory(service.category);
        setImagePreviewUrl(service.img);
    };

    return (
        <Box w='90vw' minH='100vh'>
            <HStack ml={8} mb={8}>
                <Button leftIcon={<MdKeyboardBackspace/>} variant='link' colorScheme='blue'
                onClick={()=>navigate(ROUTES.DASHBOARD_WITHDRAWALS)}>
                    Services List
                </Button>
            </HStack>
            <form onSubmit={handleSubmit} style={{
                width: '100%',
                padding: '0 20px',
                borderRadius: '5px',
            }} >
                <HStack  alignItems="flex-start" gap='20' justifyContent="center" >
                    <VStack w='30vw' alignItems='flex-start'>
                        <Text>Description</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius:8
                        }} w='100%'>
                            <FormControl id="name" marginBottom="4" isRequired>
                                <FormLabel>Name</FormLabel>
                                <Input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter product name"
                                />
                            </FormControl>
                            <FormControl id="description" marginBottom="4" isRequired>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter product description"
                                />
                            </FormControl>
                        </Box>
                        <Text>Category</Text>

                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius:8
                            
                        }} w='100%'>
                            <FormControl id="category" marginBottom="4" isRequired>
                                <FormLabel>Category</FormLabel>
                                <Select
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}
                                    placeholder="Select category"
                                >
                                    {
                                        SERVICE_CATEGORIES.map((category) => <option value={category}>{category}</option>)
                                    }

                                </Select>
                            </FormControl>
                        </Box>
                      
                    </VStack>
                    <VStack w='30vw' alignItems='flex-start'>
                        <Text>Service Image</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius:8
                        }}  w='100%'>
                            <FormControl id="image" marginBottom="4">
                                <FormLabel>Image</FormLabel>
                                <Input
                                    type="file"
                                    accept='image/*'
                                    onChange={(e) => {
                                        setImage(e.target.files[0]);
                                        setImagePreviewUrl(URL.createObjectURL(e.target.files[0]));
                                    }}
                                />
                                {imagePreviewUrl && <Image src={imagePreviewUrl} alt="Image preview" boxSize="200px" />}
                            </FormControl>
                        </Box>
                        <Text>Guide Files</Text>
                        <Box style={{
                            border: '1px solid gray',
                            padding: '10px',
                            borderRadius:8
                        }} w='100%'>
                            <FormControl id="guide" marginBottom="4" >
                                <FormLabel>Guide</FormLabel>
                                <Input
                                    type="file"
                                    accept='.pdf'
                                    onChange={(e) => {
                                        setGuide(e.target.files[0]);
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <HStack  w='100%' justifyContent="flex-end" pt={4}>
                            <Button onClick={()=>{navigate(ROUTES.DASHBOARD_WITHDRAWALS)}} bgColor="gray.200" marginRight="4" paddingX={8} color='black'>Cancel</Button>
                            <Button type="submit" colorScheme="green" isLoading={loading || isLoading} paddingX={24}>Save Changes</Button>
                        </HStack>
                    </VStack>
                </HStack>
            </form>
        </Box>
    );
};

export default EditServiceForm;