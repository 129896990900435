import { Box, Button, Divider, Flex, HStack, Heading, List, ListItem, Select, Spinner, Stack, Text, useToast, Image, Link, Table, Tbody, Td, Th, Thead, Tr, VStack, Avatar } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../services/api_service';
import { Link as RouterLink } from 'react-router-dom';
import { MdKeyboardBackspace } from "react-icons/md";
import * as ROUTES from '../../utils/constants/routes';


const OrderDetails = () => {
    const { id } = useParams();
    const toast = useToast();
    const [status, setStatus] = useState('');
    const [order, setOrder] = useState(null);
    const navigate = useNavigate();

    const {
        data, isLoading, isError, error,
    } = useQuery(`order-${id}`, () => {
        return ApiService.getOrderDetiails(id)
    }, {
        onSuccess: (data) => {
            setStatus(data.status);
            setOrder(data);
        }
    });

    const { mutate, isLoading:statusUpdateLoading, isSuccess,} = useMutation(
        () => ApiService.updateOrderStatus({orderId:id,status}),
        {
            onSuccess: () => {
                toast(
                    {
                        title: "Order Status Updated successfully",
                        description: "Product has been updated successfully",
                        status: "success",
                        duration: 5000,
                    }
                )
                navigate(ROUTES.DASHBOARD_ORDERS);
            },
            onError: () => {
                toast(
                    {
                        title: "Error Occured",
                        description: "Something went wrong! Please try again.",
                        status: "error",
                        duration: 5000,
                    }
                )
            }

        }
    )

    const handleStatusUpdate = async () => {
        try {
            //   const response = await axios.put(`/api/orders/${orderId}`, { status });
            //   setOrder(response.data); // Update order details with the new status
            mutate();
            // alert('Order status updated successfully');
        } catch (error) {
            alert(`Error updating status: ${error.message}`);
        }
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    return (
        <Box p={6} minH='100vh'>
         <HStack ml={8} mb={8}>
                <Button leftIcon={<MdKeyboardBackspace />} variant='link' colorScheme='blue'
                    onClick={() => navigate(ROUTES.DASHBOARD_ORDERS)}>
                    Order List
                </Button>
            </HStack>
            {isLoading && <Spinner />}
            {isError && <HStack justifyContent='center'><Text>{error}</Text></HStack>}
            {!isLoading && data && order && <Box p={5} maxWidth="80vw" mx="auto">
                <Heading mb={6}>Order Details</Heading>
                <Stack spacing={4}>
                    <Flex direction="row" justifyContent="space-between">
                        <Box border="1px" borderColor="gray.200" p="4" marginRight="2" borderRadius="4">
                        <HStack>
                            <Avatar size="md" src={order.user.image}  alt={order.user.username} />
                            <VStack justifyContent='flex-start'>
                                <Text>{order.user.username}</Text>
                                <Text>{order.user.email}</Text>
                            </VStack>
                        </HStack>
                        </Box>
                        <Box border="1px" borderColor="gray.200" p="4" marginRight="2" borderRadius="4">
                            <Text><strong>Order ID:</strong> {order._id}</Text>
                            <Text><strong>Delivery Charge:</strong> 0</Text>
                            <Text><strong>Payable Total :</strong> UGX {order.totalPrice}</Text>
                            <Text><strong>Payment Method:</strong> Mobile Money</Text>
                            <Text><strong>Total:</strong> UGX {order.totalPrice}</Text>
                            <Text><strong>Order Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</Text>
                        </Box>
                        <Box border="1px" borderColor="gray.200" p="4" borderRadius="4">
                            <Text><strong>Address:</strong> {`${order.address.street}, ${order.address.houseNumber}, ${order.address.zipCode}, ${order.address.state}, ${order.address.country}`}</Text>
                            <Text><strong>Mobile:</strong> {order.address.phoneNumber}</Text>
                            <Text><strong>Delivery Date & Time:</strong> _</Text>
                        </Box>
                    </Flex>
                    <Heading size="m">Items</Heading>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Image</Th>
                                <Th>Name</Th>
                                <Th>Quantity</Th>
                                <Th>Total Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {order.cart.products.map((item) => (
                                <Tr key={item.title}>
                                    <Td>
                                        <Box boxSize="50px">
                                            <Image src={item.img[0]} alt={item.title} />
                                        </Box>
                                    </Td>
                                    <Td>
                                        <Link as={RouterLink} to={`/product/${item._id}`}>
                                            {item.title}
                                        </Link>
                                    </Td>
                                    <Td>{item.quantity}</Td>
                                    <Td>UGX {item.price * item.quantity}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <Heading size="md">Update Order Status</Heading>
                    <Flex align="center">
                        <Select value={status} onChange={handleStatusChange} width="200px" mr={4}>
                            <option value="pending">Pending</option>
                            <option value="processing">Processing</option>
                            <option value="shipped">Shipped</option>
                            <option value="delivered">Delivered</option>
                            <option value="cancelled">Cancelled</option>
                        </Select>
                        <Button onClick={handleStatusUpdate} colorScheme="blue" isLoading={statusUpdateLoading}>Update Status</Button>
                    </Flex>
                </Stack>
            </Box>}
        </Box>
    );
};

export default OrderDetails;